// @flow

import React from 'react';
import classnames from 'classnames';

import { NO_SWIPING_CLASS_NAME } from "../../../config/consts";
import styles from './Button.module.css';

type Props = {
  onClick: Function,
  isContainer?: boolean,
  buttonColor?: string
};

const Button = (props: Props) => {
  if (props.isContainer) {
    return (
      <section onClick={props.onClick}>
        {props.children}
      </section>
    );
  }

  return (
    <button
        type="button"
        onClick={props.onClick}
        className={classnames({
            [NO_SWIPING_CLASS_NAME]: true,
            [styles.infoButton]: true,
            [styles.whiteButton]: props.buttonColor === 'white'
        })}
    >
      {props.children}
    </button>
  );
};

export default Button;
