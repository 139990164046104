import React from 'react';
import { Link } from 'react-router-dom';
import {bindActionCreators} from 'redux';
import { FormattedMessage } from 'react-intl';
import styles from './Logo.module.css';
import {clickLogo, clickMainButton, toggleDialog} from '../../actions';
import {connect} from "react-redux";

const Logo = props => {
    const clickHandler = () => {
        props.clickLogo();

        if (props.isDialogOpened) {
            props.clickMainButton(props.mode);
            props.toggleDialog();
        }
    }

    return (
        <Link to={`/${window.siteLanguage}`} className={styles.logo} id="droxic_logo" onClick={clickHandler}>
            <svg className={styles.logoSvg} width="130" height="87" viewBox="0 0 130 87" fill="none" xmlns="http://www.w3.org/2000/svg">
                <title><FormattedMessage id={'menu.logoTooltip'} /></title>
                <path fillRule="evenodd" clipRule="evenodd" d="M21.7105 42.9359C21.7105 54.7425 31.2363 64.3136 42.9868 64.3136C49.004 64.3136 54.71 62.098 58.58 58.0635L73.3816 43.1915L88.5789 27.9218L112.825 3.56079C114.779 1.59696 117.958 1.59697 119.912 3.5608L128.49 12.1789C130.431 14.1296 130.431 17.2824 128.49 19.2331L104.211 43.6278L89.0131 58.8975L73.8158 74.1672C66.0132 82.1708 55.0182 87 42.9868 87C19.2459 87 0 67.6625 0 43.8085C0 19.9545 19.2459 0.616986 42.9868 0.616986C54.8581 0.616986 65.9108 5.14494 73.69 12.962L81.0583 20.3653L65.8609 35.6351L58.3403 28.0786C54.4702 24.0441 49.004 21.5583 42.9868 21.5583C31.2363 21.5583 21.7105 31.1294 21.7105 42.9359Z" fill="#1EC6C6"/>
                <path fillRule="evenodd" clipRule="evenodd" d="M111.772 51.2251L96.5745 66.4949L112.824 82.8222C114.779 84.7861 117.958 84.7861 119.912 82.8222L128.055 74.6404C129.997 72.6897 129.997 69.5368 128.055 67.5862L111.772 51.2251Z" fill="#1EC6C6"/>
            </svg>
        </Link>
    );
}

const mapStateToProps = state => ({
    isDialogOpened: state.dialogState.isOpened,
    mode: state.mainButtonState.mode
});

const mapDispatchToProps = dispatch => (bindActionCreators(
    { clickLogo, toggleDialog, clickMainButton },
    dispatch));

export default connect(mapStateToProps, mapDispatchToProps)(Logo);
