// @flow

import React from 'react';
import { GridTypePage } from './GridTypePage';
import { withCanvasState } from '../../containers/withCanvasState';
import { slides } from '../../content/work';

const GridTypePageComponent = withCanvasState(null)(GridTypePage);

type Props = {
    config: {
        sliderOptions: Object
    }
};

export const Work = ({ config }: Props) => <GridTypePageComponent slides={slides} config={config} />;
