// @flow
import React from 'react';

import DefaultPage from './DefaultPage';
import { slides } from '../../content/home';

type Props = {
  config: {
    sliderOptions: Object
  },
  animate: Boolean
};

export const Home = ({ config }: Props) => (
  <DefaultPage slides={slides} config={config} />
);
