import React, { useRef, useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { gsap, Sine } from 'gsap';
import { checkScreenSizeIfMobile } from '../../../utils/utils';
import styles from './MenuLanguageBar.module.css';
import debounce from "lodash/debounce";

const MenuLanguageBar = props => {
    const { isOpened } = props;
    const containerRef = useRef(null);

    useEffect(() => {
        const baseDelay = isOpened ? .15 : 0;
        const newOpacity = isOpened ? 1 : 0;
        const paddingLeft = isOpened ? 0 : 50;
        gsap.to(containerRef.current, .2, {
            delay: baseDelay,
            opacity: newOpacity,
            paddingLeft: paddingLeft,
            ease: Sine.easeOut
        });
    }, [isOpened]);

    const [isMobile, setIsMobile] = useState(checkScreenSizeIfMobile());
    const resizeHandler = () => {
        setIsMobile(checkScreenSizeIfMobile());
    };
    useEffect(() => {
        window.addEventListener('resize', debounce(resizeHandler, 200));
    }, []);

    const currentLanguage = props.history.location.pathname.split('/')[1];
    const englishText = isMobile ? 'En' : 'English';
    const frenchText = isMobile ? 'Fr' : 'Français';
    const germanText = isMobile ? 'De' : 'Deutsch';

    return (
        <div className={styles.container} ref={containerRef}>
            <a href="/en" className={currentLanguage === 'en' ? styles.active : ''}>{englishText}</a>
            <a href="/fr" className={currentLanguage === 'fr' ? styles.active : ''}>{frenchText}</a>
            <a href="/de" className={currentLanguage === 'de' ? styles.active : ''}>{germanText}</a>
        </div>
    );
};

export default withRouter(MenuLanguageBar);
