import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import { gsap, Sine } from 'gsap';
import { isMobile } from 'react-device-detect';
import styles from './MenuLink.module.css';

const MenuLink = React.forwardRef((props, ref) => {
    const whiteElementRef = useRef(null);
    const blueElementRef = useRef(null);

    const mouseEnterHandler = event => {
        const whiteElement = whiteElementRef.current;
        const blueElement = blueElementRef.current;
        const { offsetWidth, offsetHeight } = whiteElement;

        gsap.set(blueElement, {
            css: { opacity: 1 }
        });

        gsap.set(whiteElement, {
            css: { clipPath: `polygon(0px 0px, ${offsetWidth}px 0px, ${offsetWidth}px ${offsetHeight}px, 0px ${offsetHeight}px)` }
        });

        gsap.to(whiteElement, .2, {
            css: { x: '6px', clipPath: `polygon(${offsetWidth + 2}px 0, ${2 * offsetWidth + 2}px 0, ${2 * offsetWidth + 2}px ${offsetHeight}px, ${offsetWidth + 2}px ${offsetHeight}px)` },
            delay: 0.05,
            ease: Sine.easeInOut
        });

        gsap.to(event.currentTarget, .2, {
            css: { paddingLeft: '6px' },
            ease: Sine.easeInOut
        });
    };

    const mouseLeaveEffectOnComplete = () => {
        const blueElement = blueElementRef.current;
        gsap.set(blueElement, {
            css: { opacity: 0 }
        });
    }

    const mouseLeaveHandler = event => {
        const whiteElement = whiteElementRef.current;
        const { offsetWidth, offsetHeight } = whiteElement;

        gsap.to(whiteElement, .2, {
            css: { x: '0px', clipPath: `polygon(0px 0px, ${offsetWidth}px 0px, ${offsetWidth}px ${offsetHeight}px, 0px ${offsetHeight}px)` },
            delay: 0.05,
            ease: Sine.easeInOut,
            onComplete: mouseLeaveEffectOnComplete
        });

        gsap.to(event.currentTarget, .2, {
            css: { paddingLeft: '0px' },
            ease: Sine.easeInOut
        });
    };

    const clickHandler = () => {
        props.clickMainMenuItem();
    };

    if (String(props.url).includes('http')) {
        return (
          <div ref={ref}>
              <a
                className={styles.menuLink}
                href={props.url}
                onMouseEnter={isMobile ? null : mouseEnterHandler}
                onMouseLeave={isMobile ? null : mouseLeaveHandler}
              >
                  <div ref={blueElementRef} className={styles.textElementBlue}>{props.label}</div>
                  <div ref = {whiteElementRef} className={styles.textElementWhite}>{props.label}</div>
              </a>
          </div>
        )
    }

    return (
        <div ref={ref}>
            <Link
                className={styles.menuLink}
                to={props.url}
                onMouseEnter={isMobile ? null : mouseEnterHandler}
                onMouseLeave={isMobile ? null : mouseLeaveHandler}
                onClick={clickHandler}
            >
                <div ref={blueElementRef} className={styles.textElementBlue}>{props.label}</div>
                <div ref = {whiteElementRef} className={styles.textElementWhite}>{props.label}</div>
            </Link>
        </div>
    );
});

export default MenuLink;
