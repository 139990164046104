// @flow
import {
    PAGE_TYPE_INTERNAL,
    PAGE_TYPE_EXTERNAL,
    BACKGROUND_TYPE_IMAGE,
    BACKGROUND_TYPE_VIDEO,
    BACKGROUND_TYPE_SOLID_COLOR
} from '../config/consts';

import about_video from '../videos/about.mp4';
import clients_video from '../videos/clients.mp4';
import expertise_video from '../videos/expertise.mp4';
import contacts_video from '../videos/contacts.mp4';

// MAIN BACKGROUNDS
import about_s from '../images/home/about_s.jpg';
import about_m from '../images/home/about_m.jpg';
import clients_s from '../images/home/clients_s.jpg';
import clients_m from '../images/home/clients_m.jpg';
import expertise_s from '../images/home/expertise_s.jpg';
import expertise_m from '../images/home/expertise_m.jpg';
import contacts_s from '../images/home/contacts_s.jpg';
import contacts_m from '../images/home/contacts_m.jpg';

// EXPERTISE ICONS
// Branding icons
import notepad from '../images/expertise_icons/notepad.svg';
import palette from '../images/expertise_icons/palette.svg';
// Digital platforms
import javascript from '../images/expertise_icons/javascript.svg';
import magento from '../images/expertise_icons/magento.svg';
import php from '../images/expertise_icons/php.svg';
import sitecore from '../images/expertise_icons/sitecore.svg';
import sitefinity from '../images/expertise_icons/sitefinity.svg';
// Creative icons
import figma from '../images/expertise_icons/figma.svg';
import invision from '../images/expertise_icons/invision.svg';
import sketch from '../images/expertise_icons/sketch.svg';
import xd from '../images/expertise_icons/xd.svg';
import framer from '../images/expertise_icons/framer.svg';
// Mobile icons
import android from '../images/expertise_icons/android.svg';
import apple from '../images/expertise_icons/apple.svg';

// EXPERTISE DIALOG BACKGROUNDS
import digital_platforms_s from '../images/expertise/digital_platforms_s.jpg';
import digital_platforms_m from '../images/expertise/digital_platforms_m.jpg';
import digital_platforms_l from '../images/expertise/digital_platforms_l.jpg';
import digital_platforms_xl from '../images/expertise/digital_platforms_xl.jpg';
import mobile_applications_s from '../images/expertise/mobile_applications_s.jpg';
import mobile_applications_m from '../images/expertise/mobile_applications_m.jpg';
import mobile_applications_l from '../images/expertise/mobile_applications_l.jpg';
import mobile_applications_xl from '../images/expertise/mobile_applications_xl.jpg';
import creative_directions_s from '../images/expertise/creative_directions_s.jpg';
import creative_directions_m from '../images/expertise/creative_directions_m.jpg';
import creative_directions_l from '../images/expertise/creative_directions_l.jpg';
import creative_directions_xl from '../images/expertise/creative_directions_xl.jpg';
import branding_s from '../images/expertise/branding_s.jpg';
import branding_m from '../images/expertise/branding_m.jpg';
import branding_l from '../images/expertise/branding_l.jpg';
import branding_xl from '../images/expertise/branding_xl.jpg';

// CLIENTS LOGOS
import ally from '../images/clients/ally.svg';
import bearsanddolphins from '../images/clients/bearsanddolphins.svg';
import boxuk from '../images/clients/boxuk.svg';
import brawezone from '../images/clients/brawezone.svg';
import cedar from '../images/clients/cedar.svg';
import cksk from '../images/clients/cksk.svg';
import claned from '../images/clients/claned.svg';
import dog from '../images/clients/dog.svg';
import finlauson from '../images/clients/finlauson.svg';
import getsafe from '../images/clients/getsafe.svg';
import icaw from '../images/clients/icaw.svg';
import isobar from '../images/clients/isobar.svg';
import kweak from '../images/clients/kweak.svg';
import liferheld from '../images/clients/liferheld.svg';
import lmb from '../images/clients/lmb.svg';
import madison_ally from '../images/clients/madison_ally.svg';
import mediaman from '../images/clients/mediaman.svg';
import mnstr from '../images/clients/mnstr.svg';
import mr_ms_smith from '../images/clients/mr_ms_smith.svg';
import msf from '../images/clients/msf.svg';
import oliver from '../images/clients/oliver.svg';
import ovo from '../images/clients/ovo.svg';
import siili from '../images/clients/siili.svg';
import supper from '../images/clients/supper.svg';
import unit from '../images/clients/unit.svg';
import viessman from '../images/clients/viessman.svg';
import zoomsquare from '../images/clients/zoomsquare.svg';
import interone from '../images/clients/interone.svg';
import precedent from '../images/clients/precedent.svg';
import massup from '../images/clients/massup.svg';
import ekahau from '../images/clients/ekahau.svg';
import ecx from '../images/clients/ecx.svg';
import bmw from '../images/clients/bmw.svg';
import brita from '../images/clients/brita.svg';
import british_council from '../images/clients/british-council.svg';
import cnn from '../images/clients/cnn.svg';
import heineken from '../images/clients/heineken.svg';
import martini from '../images/clients/martini.svg';
import porsche from '../images/clients/porsche.svg';
import appnovation from '../images/clients/appnovation.svg';
import fitogram from '../images/clients/fitogram.svg';
import urban_sports_club from '../images/clients/urban_sports_club.svg';
import grohe from '../images/clients/grohe.svg';
import insta from '../images/clients/insta.svg';
import gasak from '../images/clients/gasag.svg';
import progress from '../images/clients/progress.svg';
import digitalist from '../images/clients/digitalist.svg';
import mirum from '../images/clients/mirum.svg';

export const slides = [
    {
        id: 1,
        config: {
            type: 'InfoSlide',
            withPagination: true
        },
        "title": "pages.home.slides.aboutTitle",
        "subtitle": "pages.home.slides.aboutSubtitle",
        "type": PAGE_TYPE_EXTERNAL,
        "button": {
            "textKey": "pages.home.slides.aboutButtonText",
            "url": "/about"
        },
        "background": {
            "small": {
                "type": BACKGROUND_TYPE_IMAGE,
                "url": about_s
            },
            "medium": {
                "type": BACKGROUND_TYPE_IMAGE,
                "url": about_m
            },
            "large": {
                "type": BACKGROUND_TYPE_VIDEO,
                "url": about_video
            },
            "extraLarge": {
                "type": BACKGROUND_TYPE_VIDEO,
                "url": about_video
            }
        }
    },
    {
        id: 2,
        config: {
            type: 'InfoSlide',
            withPagination: true
        },
        "title": "pages.home.slides.clientsTitle",
        "subtitle": "pages.home.slides.clientsSubtitle",
        "type": PAGE_TYPE_INTERNAL,
        "button": {
            "textKey": "pages.home.slides.clientsButtonText",
            "url": "/clients"
        },
        "background": {
            "small": {
                "type": BACKGROUND_TYPE_IMAGE,
                "url": clients_s
            },
            "medium": {
                "type": BACKGROUND_TYPE_IMAGE,
                "url": clients_m
            },
            "large": {
                "type": BACKGROUND_TYPE_VIDEO,
                "url": clients_video
            },
            "extraLarge": {
                "type": BACKGROUND_TYPE_VIDEO,
                "url": clients_video
            }
        },
        dialogSlides: {
            sliderOptions: {
                slidesPerView: 1,
                mousewheel: true,
                direction: 'vertical',
                speed: 450,
                effect: 'slide',
                scrollbar: {
                    hide: false,
                    draggable: true,
                }
            },
            config: {
                mouseAnimationColor: '#19C2BD'
            },
            slides: [
                [
                    {
                        config: {
                            type: 'ClientSlide'
                        },
                        clientLogo: siili,
                        clientName: "siili",
                        "background": {
                            "extraLarge": {
                                "type": BACKGROUND_TYPE_SOLID_COLOR,
                                "url": "#FFFFFF"
                            }
                        }
                    },
                    {
                        config: {
                            type: 'ClientSlide'
                        },
                        clientLogo: liferheld,
                        clientName: "liferheld",
                        "background": {
                            "extraLarge": {
                                "type": BACKGROUND_TYPE_SOLID_COLOR,
                                "url": "#FFFFFF"
                            }
                        }
                    },
                    {
                        config: {
                            type: 'ClientSlide'
                        },
                        clientLogo: unit,
                        clientName: "unit",
                        "background": {
                            "extraLarge": {
                                "type": BACKGROUND_TYPE_SOLID_COLOR,
                                "url": "#FFFFFF"
                            }
                        }
                    },
                    {
                        config: {
                            type: 'ClientSlide'
                        },
                        clientLogo: bmw,
                        clientName: "bmw",
                        "background": {
                            "extraLarge": {
                                "type": BACKGROUND_TYPE_SOLID_COLOR,
                                "url": "#FFFFFF"
                            }
                        }
                    }
                ],
                [
                    {
                        config: {
                            type: 'ClientSlide'
                        },
                        clientLogo: grohe,
                        clientName: "grohe",
                        "background": {
                            "extraLarge": {
                                "type": BACKGROUND_TYPE_SOLID_COLOR,
                                "url": "#FFFFFF"
                            }
                        }
                    },
                    {
                        config: {
                            type: 'ClientSlide'
                        },
                        clientLogo: insta,
                        clientName: "insta",
                        "background": {
                            "extraLarge": {
                                "type": BACKGROUND_TYPE_SOLID_COLOR,
                                "url": "#FFFFFF"
                            }
                        }
                    },
                    {
                        config: {
                            type: 'ClientSlide'
                        },
                        clientLogo: gasak,
                        clientName: "gasak",
                        "background": {
                            "extraLarge": {
                                "type": BACKGROUND_TYPE_SOLID_COLOR,
                                "url": "#FFFFFF"
                            }
                        }
                    },
                    {
                        config: {
                            type: 'ClientSlide'
                        },
                        clientLogo: interone,
                        clientName: "interone",
                        "background": {
                            "extraLarge": {
                                "type": BACKGROUND_TYPE_SOLID_COLOR,
                                "url": "#FFFFFF"
                            }
                        }
                    },
                ],
                [
                    {
                        config: {
                            type: 'ClientSlide'
                        },
                        clientLogo: ovo,
                        clientName: "ovo",
                        "background": {
                            "extraLarge": {
                                "type": BACKGROUND_TYPE_SOLID_COLOR,
                                "url": "#FFFFFF"
                            }
                        }
                    },
                    {
                        config: {
                            type: 'ClientSlide'
                        },
                        clientLogo: massup,
                        clientName: "massup",
                        "background": {
                            "extraLarge": {
                                "type": BACKGROUND_TYPE_SOLID_COLOR,
                                "url": "#FFFFFF"
                            }
                        }
                    },
                    {
                        config: {
                            type: 'ClientSlide'
                        },
                        clientLogo: mediaman,
                        clientName: "mediaman",
                        "background": {
                            "extraLarge": {
                                "type": BACKGROUND_TYPE_SOLID_COLOR,
                                "url": "#FFFFFF"
                            }
                        }
                    },
                    {
                        config: {
                            type: 'ClientSlide'
                        },
                        clientLogo: ekahau,
                        clientName: "ekahau",
                        "background": {
                            "extraLarge": {
                                "type": BACKGROUND_TYPE_SOLID_COLOR,
                                "url": "#FFFFFF"
                            }
                        }
                    }
                ],
                [
                    {
                        config: {
                            type: 'ClientSlide'
                        },
                        clientLogo: claned,
                        clientName: "claned",
                        "background": {
                            "extraLarge": {
                                "type": BACKGROUND_TYPE_SOLID_COLOR,
                                "url": "#FFFFFF"
                            }
                        }
                    },
                    {
                        config: {
                            type: 'ClientSlide'
                        },
                        clientLogo: ecx,
                        clientName: "ecx",
                        "background": {
                            "extraLarge": {
                                "type": BACKGROUND_TYPE_SOLID_COLOR,
                                "url": "#FFFFFF"
                            }
                        }
                    },
                    {
                        config: {
                            type: 'ClientSlide'
                        },
                        clientLogo: british_council,
                        clientName: "british_council",
                        "background": {
                            "extraLarge": {
                                "type": BACKGROUND_TYPE_SOLID_COLOR,
                                "url": "#FFFFFF"
                            }
                        }
                    },
                    {
                        config: {
                            type: 'ClientSlide',
                            buttonColor: 'HORAY, IN inner slider #1'
                        },
                        clientLogo: ally,
                        clientName: "ally",
                        "background": {
                            "extraLarge": {
                                "type": BACKGROUND_TYPE_SOLID_COLOR,
                                "url": "#FFFFFF"
                            }
                        }
                    }
                ],
                [
                    {
                        config: {
                            type: 'ClientSlide'
                        },
                        clientLogo: bearsanddolphins,
                        clientName: "bearsanddolphins",
                        "background": {
                            "extraLarge": {
                                "type": BACKGROUND_TYPE_SOLID_COLOR,
                                "url": "#FFFFFF"
                            }
                        }
                    },
                    {
                        config: {
                            type: 'ClientSlide'
                        },
                        clientLogo: boxuk,
                        clientName: "boxuk",
                        "background": {
                            "extraLarge": {
                                "type": BACKGROUND_TYPE_SOLID_COLOR,
                                "url": "#FFFFFF"
                            }
                        }
                    },
                    {
                        config: {
                            type: 'ClientSlide'
                        },
                        clientLogo: cnn,
                        clientName: "cnn",
                        "background": {
                            "extraLarge": {
                                "type": BACKGROUND_TYPE_SOLID_COLOR,
                                "url": "#FFFFFF"
                            }
                        }
                    },
                    {
                        config: {
                            type: 'ClientSlide'
                        },
                        clientLogo: brawezone,
                        clientName: "brawezone",
                        "background": {
                            "extraLarge": {
                                "type": BACKGROUND_TYPE_SOLID_COLOR,
                                "url": "#FFFFFF"
                            }
                        }
                    }
                ],
                [
                    {
                        config: {
                            type: 'ClientSlide'
                        },
                        clientLogo: cedar,
                        clientName: "cedar",
                        "background": {
                            "extraLarge": {
                                "type": BACKGROUND_TYPE_SOLID_COLOR,
                                "url": "#FFFFFF"
                            }
                        }
                    },
                    {
                        config: {
                            type: 'ClientSlide'
                        },
                        clientLogo: cksk,
                        clientName: "cksk",
                        "background": {
                            "extraLarge": {
                                "type": BACKGROUND_TYPE_SOLID_COLOR,
                                "url": "#FFFFFF"
                            }
                        }
                    },
                    {
                        config: {
                            type: 'ClientSlide'
                        },
                        clientLogo: heineken,
                        clientName: "heineken",
                        "background": {
                            "extraLarge": {
                                "type": BACKGROUND_TYPE_SOLID_COLOR,
                                "url": "#FFFFFF"
                            }
                        }
                    },
                    {
                        config: {
                            type: 'ClientSlide'
                        },
                        clientLogo: zoomsquare,
                        clientName: "zoomsquare",
                        "background": {
                            "extraLarge": {
                                "type": BACKGROUND_TYPE_SOLID_COLOR,
                                "url": "#FFFFFF"
                            }
                        }
                    }
                ],
                [
                    {
                        config: {
                            type: 'ClientSlide'
                        },
                        clientLogo: dog,
                        clientName: "dog",
                        "background": {
                            "extraLarge": {
                                "type": BACKGROUND_TYPE_SOLID_COLOR,
                                "url": "#FFFFFF"
                            }
                        }
                    },
                    {
                        config: {
                            type: 'ClientSlide'
                        },
                        clientLogo: viessman,
                        clientName: "viessman",
                        "background": {
                            "extraLarge": {
                                "type": BACKGROUND_TYPE_SOLID_COLOR,
                                "url": "#FFFFFF"
                            }
                        }
                    },
                    {
                        config: {
                            type: 'ClientSlide'
                        },
                        clientLogo: martini,
                        clientName: "martini",
                        "background": {
                            "extraLarge": {
                                "type": BACKGROUND_TYPE_SOLID_COLOR,
                                "url": "#FFFFFF"
                            }
                        }
                    },
                    {
                        config: {
                            type: 'ClientSlide'
                        },
                        clientLogo: finlauson,
                        clientName: "finlauson",
                        "background": {
                            "extraLarge": {
                                "type": BACKGROUND_TYPE_SOLID_COLOR,
                                "url": "#FFFFFF"
                            }
                        }
                    }
                ],
                [
                    {
                        config: {
                            type: 'ClientSlide'
                        },
                        clientLogo: getsafe,
                        clientName: "getsafe",
                        "background": {
                            "extraLarge": {
                                "type": BACKGROUND_TYPE_SOLID_COLOR,
                                "url": "#FFFFFF"
                            }
                        }
                    },
                    {
                        config: {
                            type: 'ClientSlide'
                        },
                        clientLogo: icaw,
                        clientName: "icaw",
                        "background": {
                            "extraLarge": {
                                "type": BACKGROUND_TYPE_SOLID_COLOR,
                                "url": "#FFFFFF"
                            }
                        }
                    },
                    {
                        config: {
                            type: 'ClientSlide'
                        },
                        clientLogo: porsche,
                        clientName: "porsche",
                        "background": {
                            "extraLarge": {
                                "type": BACKGROUND_TYPE_SOLID_COLOR,
                                "url": "#FFFFFF"
                            }
                        }
                    },
                    {
                        config: {
                            type: 'ClientSlide'
                        },
                        clientLogo: isobar,
                        clientName: "isobar",
                        "background": {
                            "extraLarge": {
                                "type": BACKGROUND_TYPE_SOLID_COLOR,
                                "url": "#FFFFFF"
                            }
                        }
                    }
                ],
                [
                    {
                        config: {
                            type: 'ClientSlide'
                        },
                        clientLogo: kweak,
                        clientName: "kweak",
                        "background": {
                            "extraLarge": {
                                "type": BACKGROUND_TYPE_SOLID_COLOR,
                                "url": "#FFFFFF"
                            }
                        }
                    },
                    {
                        config: {
                            type: 'ClientSlide'
                        },
                        clientLogo: lmb,
                        clientName: "lmb",
                        "background": {
                            "extraLarge": {
                                "type": BACKGROUND_TYPE_SOLID_COLOR,
                                "url": "#FFFFFF"
                            }
                        }
                    },
                    {
                        config: {
                            type: 'ClientSlide'
                        },
                        clientLogo: madison_ally,
                        clientName: "madison_ally",
                        "background": {
                            "extraLarge": {
                                "type": BACKGROUND_TYPE_SOLID_COLOR,
                                "url": "#FFFFFF"
                            }
                        }
                    },
                    {
                        config: {
                            type: 'ClientSlide'
                        },
                        clientLogo: brita,
                        clientName: "brita",
                        "background": {
                            "extraLarge": {
                                "type": BACKGROUND_TYPE_SOLID_COLOR,
                                "url": "#FFFFFF"
                            }
                        }
                    }
                ],
                [
                    {
                        config: {
                            type: 'ClientSlide'
                        },
                        clientLogo: mnstr,
                        clientName: "mnstr",
                        "background": {
                            "extraLarge": {
                                "type": BACKGROUND_TYPE_SOLID_COLOR,
                                "url": "#FFFFFF"
                            }
                        }
                    },
                    {
                        config: {
                            type: 'ClientSlide'
                        },
                        clientLogo: mr_ms_smith,
                        clientName: "mr_ms_smith",
                        "background": {
                            "extraLarge": {
                                "type": BACKGROUND_TYPE_SOLID_COLOR,
                                "url": "#FFFFFF"
                            }
                        }
                    },
                    {
                        config: {
                            type: 'ClientSlide'
                        },
                        clientLogo: msf,
                        clientName: "msf",
                        "background": {
                            "extraLarge": {
                                "type": BACKGROUND_TYPE_SOLID_COLOR,
                                "url": "#FFFFFF"
                            }
                        }
                    },
                    {
                        config: {
                            type: 'ClientSlide',
                            buttonColor: 'HORAY, IN inner slider #2'
                        },
                        clientLogo: oliver,
                        clientName: "oliver",
                        "background": {
                            "extraLarge": {
                                "type": BACKGROUND_TYPE_SOLID_COLOR,
                                "url": "#FFFFFF"
                            }
                        }
                    }
                ],
                [
                    {
                        config: {
                            type: 'ClientSlide'
                        },
                        clientLogo: appnovation,
                        clientName: "appnovation",
                        "background": {
                            "extraLarge": {
                                "type": BACKGROUND_TYPE_SOLID_COLOR,
                                "url": "#FFFFFF"
                            }
                        }
                    },
                    {
                        config: {
                            type: 'ClientSlide'
                        },
                        clientLogo: supper,
                        clientName: "supper",
                        "background": {
                            "extraLarge": {
                                "type": BACKGROUND_TYPE_SOLID_COLOR,
                                "url": "#FFFFFF"
                            }
                        }
                    },
                    {
                        config: {
                            type: 'ClientSlide'
                        },
                        clientLogo: precedent,
                        clientName: "precedent",
                        "background": {
                            "extraLarge": {
                                "type": BACKGROUND_TYPE_SOLID_COLOR,
                                "url": "#FFFFFF"
                            }
                        }
                    },
                    {
                        config: {
                            type: 'ClientSlide'
                        },
                        clientLogo: fitogram,
                        clientName: "fitogram",
                        "background": {
                            "extraLarge": {
                                "type": BACKGROUND_TYPE_SOLID_COLOR,
                                "url": "#FFFFFF"
                            }
                        }
                    }
                ],
                [
                    {
                        config: {
                            type: 'ClientSlide'
                        },
                        clientLogo: urban_sports_club,
                        clientName: "urban_sports_club",
                        "background": {
                            "extraLarge": {
                                "type": BACKGROUND_TYPE_SOLID_COLOR,
                                "url": "#FFFFFF"
                            }
                        }
                    },
                    {
                        config: {
                            type: 'ClientSlide'
                        },
                        clientLogo: progress,
                        clientName: "progress",
                        "background": {
                            "extraLarge": {
                                "type": BACKGROUND_TYPE_SOLID_COLOR,
                                "url": "#FFFFFF"
                            }
                        }
                    },
                    {
                        config: {
                            type: 'ClientSlide'
                        },
                        clientLogo: digitalist,
                        clientName: "digitalist",
                        "background": {
                            "extraLarge": {
                                "type": BACKGROUND_TYPE_SOLID_COLOR,
                                "url": "#FFFFFF"
                            }
                        }
                    },
                    {
                        config: {
                            type: 'ClientSlide'
                        },
                        clientLogo: mirum,
                        clientName: "mirum",
                        "background": {
                            "extraLarge": {
                                "type": BACKGROUND_TYPE_SOLID_COLOR,
                                "url": "#FFFFFF"
                            }
                        }
                    }
                ]
            ],
            responsive: {
                small: {
                    sliderOptions: {
                        slidesPerView: 1,
                        mousewheel: true,
                        direction: 'vertical',
                        speed: 450,
                        effect: 'slide',
                        scrollbar: {
                            hide: false,
                            draggable: true,
                        }
                    },
                    slides: [
                        [
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: siili,
                                clientName: "siili",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: liferheld,
                                clientName: "liferheld",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: unit,
                                clientName: "unit",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: bmw,
                                clientName: "bmw",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            }
                        ],
                        [
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: grohe,
                                clientName: "grohe",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: insta,
                                clientName: "insta",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: gasak,
                                clientName: "gasak",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: interone,
                                clientName: "interone",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            }
                        ],
                        [
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: ovo,
                                clientName: "ovo",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: massup,
                                clientName: "massup",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: mediaman,
                                clientName: "mediaman",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: ekahau,
                                clientName: "ekahau",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            }
                        ],
                        [
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: claned,
                                clientName: "claned",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: ecx,
                                clientName: "ecx",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: british_council,
                                clientName: "british_council",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide',
                                    buttonColor: 'HORAY, IN inner slider #1'
                                },
                                clientLogo: ally,
                                clientName: "ally",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            }
                        ],
                        [
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: bearsanddolphins,
                                clientName: "bearsanddolphins",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: boxuk,
                                clientName: "boxuk",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: cnn,
                                clientName: "cnn",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: brawezone,
                                clientName: "brawezone",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            }
                        ],
                        [
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: cedar,
                                clientName: "cedar",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: cksk,
                                clientName: "cksk",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: heineken,
                                clientName: "heineken",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: zoomsquare,
                                clientName: "zoomsquare",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            }
                        ],
                        [
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: dog,
                                clientName: "dog",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: viessman,
                                clientName: "viessman",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: martini,
                                clientName: "martini",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: finlauson,
                                clientName: "finlauson",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            }
                        ],
                        [
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: getsafe,
                                clientName: "getsafe",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: icaw,
                                clientName: "icaw",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: porsche,
                                clientName: "porsche",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: isobar,
                                clientName: "isobar",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            }
                        ],
                        [
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: kweak,
                                clientName: "kweak",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: lmb,
                                clientName: "lmb",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: madison_ally,
                                clientName: "madison_ally",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: brita,
                                clientName: "brita",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            }
                        ],
                        [
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: mnstr,
                                clientName: "mnstr",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: mr_ms_smith,
                                clientName: "mr_ms_smith",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: msf,
                                clientName: "msf",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide',
                                    buttonColor: 'HORAY, IN inner slider #2'
                                },
                                clientLogo: oliver,
                                clientName: "oliver",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            }
                        ],
                        [
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: appnovation,
                                clientName: "appnovation",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: supper,
                                clientName: "supper",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: precedent,
                                clientName: "precedent",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: fitogram,
                                clientName: "fitogram",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            }
                        ],
                        [
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: urban_sports_club,
                                clientName: "urban_sports_club",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: progress,
                                clientName: "progress",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: digitalist,
                                clientName: "digitalist",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: mirum,
                                clientName: "mirum",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            }
                        ]
                    ]
                },
                medium: {
                    sliderOptions: {
                        slidesPerView: 1,
                        mousewheel: true,
                        direction: 'vertical',
                        speed: 450,
                        effect: 'slide',
                        scrollbar: {
                            hide: false,
                            draggable: true,
                        }
                    },
                    slides: [
                        [
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: siili,
                                clientName: "siili",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: liferheld,
                                clientName: "liferheld",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: unit,
                                clientName: "unit",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: bmw,
                                clientName: "bmw",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: grohe,
                                clientName: "grohe",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: insta,
                                clientName: "insta",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            }
                        ],
                        [
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: gasak,
                                clientName: "gasak",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: interone,
                                clientName: "interone",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: ovo,
                                clientName: "ovo",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: massup,
                                clientName: "massup",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: mediaman,
                                clientName: "mediaman",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: ekahau,
                                clientName: "ekahau",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            }
                        ],
                        [
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: claned,
                                clientName: "claned",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: ecx,
                                clientName: "ecx",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: british_council,
                                clientName: "british_council",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide',
                                    buttonColor: 'HORAY, IN inner slider #1'
                                },
                                clientLogo: ally,
                                clientName: "ally",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: bearsanddolphins,
                                clientName: "bearsanddolphins",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: boxuk,
                                clientName: "boxuk",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            }
                        ],
                        [
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: cnn,
                                clientName: "cnn",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: brawezone,
                                clientName: "brawezone",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: cedar,
                                clientName: "cedar",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: cksk,
                                clientName: "cksk",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: heineken,
                                clientName: "heineken",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: zoomsquare,
                                clientName: "zoomsquare",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            }
                        ],
                        [
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: dog,
                                clientName: "dog",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: viessman,
                                clientName: "viessman",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: martini,
                                clientName: "martini",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: finlauson,
                                clientName: "finlauson",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: getsafe,
                                clientName: "getsafe",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: icaw,
                                clientName: "icaw",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            }
                        ],
                        [
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: porsche,
                                clientName: "porsche",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: isobar,
                                clientName: "isobar",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: kweak,
                                clientName: "kweak",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: lmb,
                                clientName: "lmb",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: madison_ally,
                                clientName: "madison_ally",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: brita,
                                clientName: "brita",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            }
                        ],
                        [
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: mnstr,
                                clientName: "mnstr",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: mr_ms_smith,
                                clientName: "mr_ms_smith",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: msf,
                                clientName: "msf",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide',
                                    buttonColor: 'HORAY, IN inner slider #2'
                                },
                                clientLogo: oliver,
                                clientName: "oliver",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: appnovation,
                                clientName: "appnovation",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: supper,
                                clientName: "supper",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            }
                        ],
                        [
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: precedent,
                                clientName: "precedent",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: fitogram,
                                clientName: "fitogram",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: urban_sports_club,
                                clientName: "urban_sports_club",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: progress,
                                clientName: "progress",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: digitalist,
                                clientName: "digitalist",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: mirum,
                                clientName: "mirum",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            }
                        ]
                    ]
                },
                large: {
                    sliderOptions: {
                        slidesPerView: 1,
                        mousewheel: true,
                        direction: 'vertical',
                        speed: 450,
                        effect: 'slide',
                        scrollbar: {
                            hide: false,
                            draggable: true,
                        }
                    },
                    slides: [
                        [
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: siili,
                                clientName: "siili",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: liferheld,
                                clientName: "liferheld",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: unit,
                                clientName: "unit",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: bmw,
                                clientName: "bmw",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: grohe,
                                clientName: "grohe",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: insta,
                                clientName: "insta",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: gasak,
                                clientName: "gasak",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: interone,
                                clientName: "interone",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: ovo,
                                clientName: "ovo",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            }
                        ],
                        [
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: massup,
                                clientName: "massup",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: mediaman,
                                clientName: "mediaman",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: ekahau,
                                clientName: "ekahau",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: claned,
                                clientName: "claned",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: ecx,
                                clientName: "ecx",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: british_council,
                                clientName: "british_council",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide',
                                    buttonColor: 'HORAY, IN inner slider #1'
                                },
                                clientLogo: ally,
                                clientName: "ally",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: bearsanddolphins,
                                clientName: "bearsanddolphins",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: boxuk,
                                clientName: "boxuk",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            }
                        ],
                        [
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: cnn,
                                clientName: "cnn",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: brawezone,
                                clientName: "brawezone",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: cedar,
                                clientName: "cedar",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: cksk,
                                clientName: "cksk",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: heineken,
                                clientName: "heineken",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: zoomsquare,
                                clientName: "zoomsquare",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: dog,
                                clientName: "dog",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: viessman,
                                clientName: "viessman",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: martini,
                                clientName: "martini",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            }
                        ],
                        [
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: finlauson,
                                clientName: "finlauson",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: getsafe,
                                clientName: "getsafe",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: icaw,
                                clientName: "icaw",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: porsche,
                                clientName: "porsche",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: isobar,
                                clientName: "isobar",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: kweak,
                                clientName: "kweak",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: lmb,
                                clientName: "lmb",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: madison_ally,
                                clientName: "madison_ally",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: brita,
                                clientName: "brita",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            }
                        ],
                        [
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: mnstr,
                                clientName: "mnstr",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: mr_ms_smith,
                                clientName: "mr_ms_smith",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: msf,
                                clientName: "msf",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide',
                                    buttonColor: 'HORAY, IN inner slider #2'
                                },
                                clientLogo: oliver,
                                clientName: "oliver",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: appnovation,
                                clientName: "appnovation",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: supper,
                                clientName: "supper",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: precedent,
                                clientName: "precedent",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: fitogram,
                                clientName: "fitogram",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: urban_sports_club,
                                clientName: "urban_sports_club",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            }
                        ],
                        [
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: progress,
                                clientName: "progress",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: digitalist,
                                clientName: "digitalist",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            },
                            {
                                config: {
                                    type: 'ClientSlide'
                                },
                                clientLogo: mirum,
                                clientName: "mirum",
                                "background": {
                                    "extraLarge": {
                                        "type": BACKGROUND_TYPE_SOLID_COLOR,
                                        "url": "#FFFFFF"
                                    }
                                }
                            }
                        ]
                    ]
                }
            }
        }
    },
    {
        id: 3,
        config: {
            type: 'InfoSlide',
            withPagination: true,
            className: 'slide-home-expertise'
        },
        "title": "pages.home.slides.expertiseTitle",
        "subtitle": "pages.home.slides.expertiseSubtitle",
        "type": PAGE_TYPE_INTERNAL,
        "button": {
            "textKey": "pages.home.slides.expertiseButtonText",
            "url": "/expertise"
        },
        "background": {
            "small": {
                "type": BACKGROUND_TYPE_IMAGE,
                "url": expertise_s
            },
            "medium": {
                "type": BACKGROUND_TYPE_IMAGE,
                "url": expertise_m
            },
            "large": {
                "type": BACKGROUND_TYPE_VIDEO,
                "url": expertise_video
            },
            "extraLarge": {
                "type": BACKGROUND_TYPE_VIDEO,
                "url": expertise_video
            }
        },
        dialogSlides: {
            sliderOptions: {
                slidesPerView: 1,
                mousewheel: true,
                direction: 'vertical',
                speed: 450,
                effect: 'slide',
                scrollbar: {
                    hide: false,
                    draggable: true,
                }
            },
            config: {
                mouseAnimationColor: '#000000'
            },
            slides: [
                {
                    id: 1,
                    config: {
                        type: 'InfoSlide',
                        className: 'slide-expertise',
                        withMouseAnimation: true
                    },
                    "icons": [
                        javascript,
                        magento,
                        php,
                        sitecore,
                        sitefinity
                    ],
                    "title": "pages.about.dialogs.expertise.digitalPlatformsTitle",
                    "subtitle": "pages.about.dialogs.expertise.digitalPlatformsSubtitle",
                    "type": PAGE_TYPE_EXTERNAL,
                    "background": {
                        "small": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": digital_platforms_s
                        },
                        "medium": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": digital_platforms_m
                        },
                        "large": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": digital_platforms_l
                        },
                        "extraLarge": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": digital_platforms_xl
                        }
                    }
                },
                {
                    id: 2,
                    config: {
                        type: 'InfoSlide',
                        className: 'slide-expertise--next-page'
                    },
                    "icons": [
                        apple,
                        android
                    ],
                    "title": "pages.about.dialogs.expertise.mobileApplicationsTitle",
                    "subtitle": "pages.about.dialogs.expertise.mobileApplicationsSubtitle",
                    "type": PAGE_TYPE_EXTERNAL,
                    "background": {
                        "small": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": mobile_applications_s
                        },
                        "medium": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": mobile_applications_m
                        },
                        "large": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": mobile_applications_l
                        },
                        "extraLarge": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": mobile_applications_xl
                        }
                    }
                },
                {
                    id: 3,
                    config: {
                        type: 'InfoSlide',
                        className: 'slide-expertise--next-page'
                    },
                    "icons": [
                        figma,
                        sketch,
                        xd,
                        framer,
                        invision
                    ],
                    "title": "pages.about.dialogs.expertise.creativeDirectionsTitle",
                    "subtitle": "pages.about.dialogs.expertise.creativeDirectionsSubtitle",
                    "type": PAGE_TYPE_EXTERNAL,
                    "background": {
                        "small": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": creative_directions_s
                        },
                        "medium": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": creative_directions_m
                        },
                        "large": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": creative_directions_l
                        },
                        "extraLarge": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": creative_directions_xl
                        }
                    }
                },
                {
                    id: 4,
                    config: {
                        type: 'InfoSlide',
                        className: 'slide-expertise--next-page'
                    },
                    "icons": [
                        notepad,
                        palette
                    ],
                    "title": "pages.about.dialogs.expertise.brandingTitle",
                    "subtitle": "pages.about.dialogs.expertise.brandingSubtitle",
                    "type": PAGE_TYPE_EXTERNAL,
                    "background": {
                        "small": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": branding_s
                        },
                        "medium": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": branding_m
                        },
                        "large": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": branding_l
                        },
                        "extraLarge": {
                            "type": BACKGROUND_TYPE_IMAGE,
                            "url": branding_xl
                        }
                    }
                }
            ]
        }
    },
    {
        id: 4,
        config: {
            type: 'InfoSlide',
            withPagination: true
        },
        "title": "pages.home.slides.contactsTitle",
        "subtitle": "pages.home.slides.contactsSubtitle",
        "type": PAGE_TYPE_EXTERNAL,
        "button": {
            "textKey": "pages.home.slides.contactsButtonText",
            "url": "/contacts"
        },
        "background": {
            "small": {
                "type": BACKGROUND_TYPE_IMAGE,
                "url": contacts_s
            },
            "medium": {
                "type": BACKGROUND_TYPE_IMAGE,
                "url": contacts_m
            },
            "large": {
                "type": BACKGROUND_TYPE_VIDEO,
                "url": contacts_video
            },
            "extraLarge": {
                "type": BACKGROUND_TYPE_VIDEO,
                "url": contacts_video
            }
        }
    }
];
