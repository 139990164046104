// @flow
import queryString from 'query-string';

import { history } from '../Router';

export const resolveUrlPath = (path: string) => {
  const languagePath = 
    path.charAt(0) === '/' ? window.siteLanguage : `${window.siteLanguage}/`;

  return `/${languagePath}${path}`;
};

export const getDialogIdFromUrl = () => {
  const searchParams = queryString.parse(history.location.search);
  const openedDialogId = searchParams.dialog ? Number(searchParams.dialog) : -1;

  return openedDialogId;
}