// @flow

import findKey from 'lodash/findKey';

export const LAYOUT_KEYS = {
    small: 'small',
    medium: 'medium',
    large: 'large',
    extraLarge: 'extraLarge'
};

export const RESPONSIVE = {
    screenExtraSmallMax: 599,
    screenSmall: 600,
    screenSmallMax: 767,
    screenMedium: 768,
    screenMediumMax: 1023,
    screenLarge: 1024,
    screenLargeMax: 1279,
    screenExtraLarge: 1280
};

const SCREEN_SIZE_TO_GRID_ITEMS = {
    small: 4,
    medium: 4,
    large: 9,
    extraLarge: 9
};

export function flattenDictionary(nestedMessages: Object = {}, prefix: string = ''): Object {
    return Object.keys(nestedMessages).reduce((messages, key) => {
        const accumResult = { ...messages };
        const value = nestedMessages[key];
        const prefixedKey = prefix ? `${prefix}.${key}` : key;

        if (typeof value === 'string') {
            accumResult[prefixedKey] = value;
        } else {
            Object.assign(accumResult, flattenDictionary(value, prefixedKey));
        }

        return accumResult;
    }, {});
}

export function getViewportWidth() {
    return window.innerWidth;
}

export function isRetina() {
    const matchMedia = window.matchMedia;

    if (!matchMedia) {
        return false;
    }

    const mq = matchMedia("only screen and (-moz-min-device-pixel-ratio: 1.3), only screen and (-o-min-device-pixel-ratio: 2.6/2), only screen and (-webkit-min-device-pixel-ratio: 1.3), only screen  and (min-device-pixel-ratio: 1.3), only screen and (min-resolution: 1.3dppx)");

    return !!(mq && mq.matches);
}

export const detectResponsiveLayout = (viewportWidth: number = getViewportWidth()): string => {
    const currentLayout = {
        [LAYOUT_KEYS.small]: viewportWidth < RESPONSIVE.screenSmall,
        [LAYOUT_KEYS.medium]: viewportWidth >= RESPONSIVE.screenSmall && viewportWidth < RESPONSIVE.screenLarge,
        [LAYOUT_KEYS.large]: viewportWidth >= RESPONSIVE.screenLarge && viewportWidth < RESPONSIVE.screenExtraLarge,
        [LAYOUT_KEYS.extraLarge]: viewportWidth >= RESPONSIVE.screenExtraLarge
    };

    return findKey(currentLayout);
};

export function extractSlideByDevice(slides: Object): Object {
    const screenSize = detectResponsiveLayout();
    const key = screenSize === LAYOUT_KEYS.extraLarge ? LAYOUT_KEYS.large : screenSize;

    if (slides && slides.responsive && slides.responsive[key]) {
        return slides.responsive[key];
    }

    return slides;
}

export function getSlidesPerResponsiveLayout() {
    return SCREEN_SIZE_TO_GRID_ITEMS[detectResponsiveLayout()];
}

