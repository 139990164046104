import React, { useState, useEffect } from 'react';
import isArray from 'lodash/isArray';
import classnames from 'classnames';
import SwiperCore, { A11y, Mousewheel, Scrollbar, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import Slides from './slides';
import MouseAnimation from '../MouseAnimation';

SwiperCore.use([A11y, Mousewheel, Scrollbar, Navigation]);

const Dialog = props => {
    const extractSlideIds = slideIndex => {
        return Array.isArray(props.slides[slideIndex]) ? props.slides[slideIndex].map(slide => slide.id) : [props.slides[slideIndex].id];
    };

    const [currentSlideIDs, setCurrentSlideIDs] = useState(extractSlideIds(0));

    const [showMouseAnimation, setShowMouseAnimation] = useState(true);

    const [swiper, setSwiper] = useState(null);

    const handleSlideChange = () => {
        setCurrentSlideIDs(extractSlideIds(swiper.activeIndex));
        setShowMouseAnimation(false);
    };

    useEffect(() => {
        if(swiper) {
            swiper.on('slideChange', handleSlideChange);
        }
    }, [swiper]);

    const { logo, config, customConfig } = props;

    useEffect(() => {
        const { isOpened, logo } = props;

        if (logo && isOpened) {
            document.getElementById("droxic_logo").style.display = 'none';
        }
        else if (logo && !isOpened) {
            document.getElementById("droxic_logo").style.display = '';
        }
    }, [props.isOpened]);

    return (
        <div
            className={classnames(
                'drx-dialog',
                {'drx-dialog-open': props.isOpened},
                {[config && config.className]: config && config.className}
            )}
        >
            {logo && <img src={logo} alt="Project Logo" className="drx-dialog-fixed-logo" />}
            <Swiper
                {...props.sliderOptions}
                onSwiper={(swiper) => setSwiper(swiper)}
            >
                {props.slides && props.slides.map((element, index, array) => {
                    const slideItemsCount = element.length;

                    if (isArray(element) && slideItemsCount) {
                        const previousLength = index > 0 ? array[index - 1].length : 0;

                        const calcSildesCount = (
                            previousLength > 0 &&
                                slideItemsCount < previousLength
                            )
                            ? previousLength
                            : slideItemsCount;

                        return (
                            <SwiperSlide key={index} className={classnames('slide-item', `slide-item-flex`, `slide-item-count-${calcSildesCount}`)}>
                                {element.map((i, j) => {
                                    const SlideComponent = Slides[i.config.type];
                                    return (
                                        <section
                                            key={`dialog-inner-${index}-${j}`}
                                            className={
                                                classnames({
                                                    [i.config.className]: true,
                                                    'small-slide-item': i.config.smallSlideItem,
                                                    'big-slide-item': i.config.bigSlideItem
                                                })
                                            }
                                        >
                                            <SlideComponent {...i} onClick={() => {}} currentSlideIDs={currentSlideIDs} />
                                        </section>
                                    );
                                })}
                            </SwiperSlide>
                        );
                    }

                    const SlideComponent = Slides[element.config.type];

                    return (
                        <SwiperSlide key={`dialog-outer-${index}`} className={classnames('slide-item', element.config.className)}>
                            <SlideComponent {...element} onClick={() => {}} currentSlideIDs={currentSlideIDs} />
                        </SwiperSlide>
                    );
                })}
            </Swiper>

            {
                ((customConfig && customConfig.mouseAnimationColor) || (config && config.mouseAnimationColor)) &&
                <MouseAnimation
                    showMouseAnimation={showMouseAnimation}
                    color={customConfig ? customConfig.mouseAnimationColor : config.mouseAnimationColor}
                />
            }
        </div>
    )
};

export default Dialog;
