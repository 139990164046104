// @flow

import React from 'react';
import DefaultPage from './DefaultPage';


import { slides } from '../../content/about';

type Props = {
    config: {
        sliderOptions: Object
    },
    animate: Boolean
};

export const About = ({ config }: Props) => (
    <DefaultPage slides={slides} config={config} />
)

