import { TOGGLE_DIALOG } from "../actions/index";

import { getDialogIdFromUrl } from '../utils/routerUtility';

export default function(state = {
  isOpened: getDialogIdFromUrl() !== -1 ? true : false
}, action) {
  switch(action.type) {
    case TOGGLE_DIALOG:
        return { isOpened: !state.isOpened };
    default: break;
  }
  return state;
}
