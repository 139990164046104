import React from 'react';
import classnames from 'classnames';
import styles from './MenuContainer.module.css';

const MenuContainer = ({ isOpened, children }) => {
    const classes = classnames(
        styles.menuBackground,
        { [`${styles.menuBackgroundOpen}`]: isOpened }
    );

    return (
        <div className={classes}>{children}</div>
    );
};

export default MenuContainer;
