// @flow

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { CHANGE_CANVAS_BUTTONS_COLORS } from "../actions/index.js";

function defaultMapStateToProps({ mainButtonState }) {
  return { mainButtonState };
}

function defaultMapDispatchToProps(dispatch) {
  return { changeCanvasButtonColorsAction: (payload) => dispatch({ type: CHANGE_CANVAS_BUTTONS_COLORS, payload }) };
}

export const withCanvasState = (mapStateToProps?: ?Function = defaultMapStateToProps, mapDispatchToProps: Function = defaultMapDispatchToProps) => (Component: Class<React.Component<*>>):Class<React.Component<*>>  => {
  return connect(mapStateToProps, mapDispatchToProps)(Component);
}
