// @flow

import React from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

import { NO_SWIPING_CLASS_NAME } from "../../../config/consts";
import styles from './Button.module.css';

type Props = {
    url: string,
    isExternal?: bool
};

const ButtonLink = (props: Props) => {
    const { url, isExternal } = props;

    if (isExternal) {
        return (
            <a
                href={url}
                className={classnames({
                    [NO_SWIPING_CLASS_NAME]: true,
                    [styles.infoButton]: true
                })}
            >
                {props.children}
            </a>
        )
    }

    return (
        <Link
            to={url}
            className={classnames({
                [NO_SWIPING_CLASS_NAME]: true,
                [styles.infoButton]: true
            })}
        >
            {props.children}
        </Link>
    );
};

export default ButtonLink;
