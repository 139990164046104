// @flow

import React, { PureComponent } from 'react';
import classnames from 'classnames';
import { Source, detectSource } from '../source/Source';
import { FormattedMessage } from 'react-intl';

import './InfoSlide.css';
import './WorkDialogSlide.css';

type Props = {
    title?: string,
    subtitle?: string,
    description?: string,
    background?: Object,
    image?: Object,
    logo?: string,
    icons?: Array<*>,
    onClick?: Function,
    button?: {
        url: string,
        textKey: string
    },
    newRoute: string,
    config: Object
};

export class WorkDialogSlide extends PureComponent<*, Props, *> {
    render() {
        const { background, title, subtitle, description, image, logo, config } = this.props;

        return (
          <section className={classnames('info-slide', config.variant)}>
              { background && <Source src={detectSource(background)} className="info-slide-background" isBackground={true} />}
              <div className="work-slide-details">
                <div>
                  <div className="work-slide-header">
                    { image && config.variant === 'work-slide-item-v2' && <div className="work-slide-image-wrapper"><img src={image} className="work-slide-image" alt="" /></div>}
                    <div>
                      { title &&
                        <h1>
                          <FormattedMessage id={title} />
                        </h1>
                      }
                      { subtitle &&
                        <h2>
                          <FormattedMessage id={subtitle} />
                        </h2>
                      }
                      { description &&
                        <p className="info-slide-description">
                          <FormattedMessage id={description} />
                        </p>
                      }
                    </div>
                    <div>
                      { logo && <img src={logo} alt="" className="work-slide-logo" />}
                    </div>
                  </div>
                  {image && config.variant === 'work-slide-item-v1' && <img src={image} alt="" className="work-slide-image" />}
                </div>
              </div>
          </section>
        );
    }
}
