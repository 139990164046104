import React, { useState } from 'react';
import Cookies from 'js-cookie';
import styles from './CookieMessage.module.css';
import Button from "../common/button/Button";

const COOKIE_NAME = 'drx_privacy';
const COOKIE_EXPIRATION_TIME = 356; // 1 year

const CookieMessage = ({ text, buttonText }) => {
    let [isCookieSet, setIsCookieSet] = useState(Cookies.get(COOKIE_NAME))

    const clickHandler = () => {
        setIsCookieSet(true);
        Cookies.set(COOKIE_NAME, 'true', { expires: COOKIE_EXPIRATION_TIME });
    }

    if (isCookieSet) return null;

    return (
        <section className={styles.container}>
            <p>{text}</p>
            <div className="drx-cookie-message-btn-wrapper">
                <Button onClick={clickHandler} buttonColor="white">{buttonText}</Button>
            </div>
        </section>
    );
};

export default CookieMessage;
