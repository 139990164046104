import React, { useRef, useEffect } from 'react';
import {gsap, Expo} from 'gsap';
import PageBackground from "../../page_background";
import styles from './ContactPage.module.css';

const ContactPage = props => {
    const cityRef = useRef(null);
    const address1Ref = useRef(null);
    const address2Ref = useRef(null);
    const address3Ref = useRef(null);
    const address4Ref = useRef(null);
    const telRef = useRef(null);
    const emailRef = useRef(null);
    const websiteRef = useRef(null);

    //Show/hide the text elements in both mobile and desktop after changing the slide
    const prevSlideIndexRef = useRef(props.currentSlideIndex);
    useEffect(() => {
        if (props.index === props.currentSlideIndex) {
            show(.25);
        } else if (props.index === prevSlideIndexRef.current) {
            hide();
        } else {
            hide(0);
        }
        prevSlideIndexRef.current = props.currentSlideIndex;
    }, [props.currentSlideIndex]);

    const {
        city,
        address1,
        address2,
        address3,
        address4,
        tel,
        email,
        website
    } = props.contactInfo;

    const getPresentedElements = () => {
        const allElements = [
            cityRef.current,
            address1Ref.current,
            address2Ref.current,
            address3Ref.current,
            address4Ref.current,
            telRef.current,
            emailRef.current,
            websiteRef.current
        ];
        return allElements.filter(element => element);
    };

    const show = (baseDelay = 0) => {
        const presentedElements = getPresentedElements();

        presentedElements.forEach((element, index) => {
            gsap.fromTo(element, .35,
                {
                    x: 25,
                    y: 0,
                    opacity: 0,
                },
                {
                    x: 0,
                    Y: 0,
                    opacity: 1,
                    delay: baseDelay + ((index * .05) + .125),
                    ease: Expo.easeOut
                }
            );
        });
    };

    const hide = (duration = .5, baseDelay = 0) => {
        const presentedElements = getPresentedElements();

        presentedElements.forEach((element, index) => {
            if (duration === 0) {
                gsap.set(element, {
                    x: -25,
                    y: 0,
                    opacity: 0
                });
            } else {
                gsap.to(element, duration,
                    {
                        x: -25,
                        y: 0,
                        opacity: 0,
                        delay: baseDelay + ((index * .025) + .1),
                        ease: Expo.easeOut
                    }
                );
            }
        });
    };

    return (
        <div className={styles.mainContainer}>
            <div className={styles.backgroundContainer}>
                <PageBackground { ...props }/>
            </div>
            <address>
                <div className={styles.city} ref={cityRef}>{city}</div>
                <div>
                    {
                        address1 &&
                        <div className={styles.addressLine} ref = {address1Ref}>{address1}</div>
                    }

                    {
                        address2 &&
                        <div className={styles.addressLine} ref = {address2Ref}>{address2}</div>
                    }

                    {
                        address3 &&
                        <div className={styles.addressLine} ref = {address3Ref}>{address3}</div>
                    }

                    {
                        address4 &&
                        <div className={styles.addressLine} ref = {address4Ref}>{address4}</div>
                    }
                </div>

                <a href={`tel:${tel}`} className={styles.tel} ref = {telRef}>{tel}</a>
                <a href={`mailto:${email}`} className={styles.email} ref = {emailRef}>{email}</a>
                {
                    website &&
                    <a href={`http://${website}`} target="_blank" className={styles.email} ref = {websiteRef}>{website}</a>
                }
            </address>
        </div>
    );
};

export default ContactPage;
