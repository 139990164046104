// @flow

import React, { PureComponent } from 'react';
import classnames from 'classnames';
import { Source, detectSource } from '../source/Source';
import Button from '../button/Button';
import { getSlidesPerResponsiveLayout } from '../../../libs/utils';

import './InfoSlide.css';

const ITEMS_PER_SLIDE = getSlidesPerResponsiveLayout();

type Props = {
    title?: string,
    subtitle?: string,
    description?: string,
    background?: Object,
    image?: Object,
    icons?: Array<*>,
    slides: Array<*>,
    onClick?: Function,
    button?: {
        url: string,
        textKey: string
    },
    newRoute: string
};

export class WorkSlide extends PureComponent<*, Props, *> {
    handleClick = (type: string, id: number | string) => (e: Object) => {
        if (this.props.onClick) {
            this.props.onClick(type, id, e);
        }
    }

    render() {
        const { slides } = this.props;

        return (
            <section className={classnames('slide-item-work', 'slide-loaded', `slide-item-flex`, `slide-item-count-${ITEMS_PER_SLIDE}`)}>
                {slides.map(({background, logo, dialogSlides, type, id, ...restProps}, k) => {
                    const WrapComponent = dialogSlides ? Button : 'section';
                    const wrapComponentProps = dialogSlides ? {isContainer: true} : {};
                    return (
                        <WrapComponent
                            key={`work-slide-${k}`}
                            onClick={dialogSlides ? this.handleClick(type, id) : null}
                            type={type}
                            {...wrapComponentProps}
                        >
                            <div className="work-item">
                                { background && <Source key={k} src={detectSource(background)} className="info-slide-background" isBackground={true} />}
                                <div className="work-item-content">
                                    <img src={logo} alt="" />
                                </div>
                            </div>
                        </WrapComponent>
                    );
                })}
            </section>
        );
    }
}
