import { MAIN_BUTTON_CLICKED } from "../actions/index";
import { MAIN_MENU_ITEM_CLICKED } from "../actions/index";
import { MAIN_BUTTON_MODE_NORMAL, MAIN_BUTTON_MODE_X_MENU, MAIN_BUTTON_MODE_X_SUBSECTION } from '../config/consts';
import { FUNCTIONAL_BUTTON_CLICKED } from "../actions/index";
import { ABOUT_SUBPAGE_CLICKED, CHANGE_CANVAS_BUTTONS_COLORS } from "../actions/index";

import { getDialogIdFromUrl } from '../utils/routerUtility';

export default function(state = {
    mode: getDialogIdFromUrl() !== -1 ? MAIN_BUTTON_MODE_X_SUBSECTION : MAIN_BUTTON_MODE_NORMAL,
    linesNormalColor: "#000000",
    linesOverColor: "#02CBC9",
    xNormalColor: "#FFFFFF",
    xOverColor: "#02CBC9"
}, action) {
    // Refactor this part since it is not very precise to check like this
    const isNotOnWorkPage = window.location.href.indexOf('work') === -1;

    switch(action.type) {
        case MAIN_BUTTON_CLICKED:
            if(state.mode === MAIN_BUTTON_MODE_NORMAL)
            {
                return { ...state, mode: MAIN_BUTTON_MODE_X_MENU, linesNormalColor: '#02CBC9'/*, linesNormalColor: "#000000", linesOverColor: "#02CBC9", xNormalColor: "#FFFFFF", xOverColor: "#02CBC9"*/ };
            }
            else if (state.mode === MAIN_BUTTON_MODE_X_MENU)
            {
                return { ...state, mode: MAIN_BUTTON_MODE_NORMAL, linesNormalColor: isNotOnWorkPage ? '#000000' : '#02CBC9' /*"#000000"*//*, linesNormalColor: "#000000", linesOverColor: "#02CBC9", xNormalColor: "#FFFFFF", xOverColor: "#02CBC9"*/ };
            }
            else if (state.mode === MAIN_BUTTON_MODE_X_SUBSECTION)
            {
                return { ...state, mode: MAIN_BUTTON_MODE_NORMAL, linesNormalColor: "#000000"/*, linesNormalColor: "#000000", linesOverColor: "#02CBC9", xNormalColor: "#FFFFFF", xOverColor: "#02CBC9"*/ };
            }
            break;
        case MAIN_MENU_ITEM_CLICKED:
            return { ...state, mode: MAIN_BUTTON_MODE_NORMAL, linesNormalColor: "#000000"/*, linesNormalColor: "#000000", linesOverColor: "#02CBC9", xNormalColor: "#FFFFFF", xOverColor: "#02CBC9"*/ };
        case FUNCTIONAL_BUTTON_CLICKED:
            if (action.payload.pathType === "internal")
            {
                return { ...state, mode: MAIN_BUTTON_MODE_X_SUBSECTION/*, linesNormalColor: "#000000", linesOverColor: "#02CBC9", xNormalColor: "#000000", xOverColor: "#02CBC9"*/ };
            }
            break;
        case ABOUT_SUBPAGE_CLICKED:
            if (action.payload.pathType === "internal")
            {
                return { ...state, mode: MAIN_BUTTON_MODE_X_SUBSECTION/*, linesNormalColor: "#000000", linesOverColor: "#02CBC9", xNormalColor: "#000000", xOverColor: "#02CBC9"*/ };
            }
            break;
        case CHANGE_CANVAS_BUTTONS_COLORS:
            return {
                ...state,
                linesNormalColor: action.payload.linesNormalColor,
                linesOverColor: action.payload.linesOverColor,
                xNormalColor: action.payload.xNormalColor,
                xOverColor: action.payload.xOverColor
            }
        default:
            break;
    }
    return state;
}