import { combineReducers } from 'redux';
import MenuState from './reducer_menu_state';
import NewRoute from './reducer_new_route';
import TopSectionState from './reducer_top_section';
import MainButtonState from './reducer_main_button_state';
import DialogState from './dialog';

const rootReducer = combineReducers({
    menuState: MenuState,
    newRoute:NewRoute,
    topSectionState: TopSectionState,
    mainButtonState: MainButtonState,
    dialogState: DialogState
});

export default rootReducer;
