// @flow

import React from 'react';
import { FormattedMessage } from 'react-intl';
import './PrivacyPolicy.css';

type Props = {
    config: {
        sliderOptions: Object
    }
};

export const PrivacyPolicy = ({ config }: Props) => {
    return (
      <FormattedMessage id="privacy.page.content">
        {(txt) => (
          <div className="page-content-common" dangerouslySetInnerHTML={{__html: txt}} />
        )}
      </FormattedMessage>
    );
}
