import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import isArray from 'lodash/isArray';
import map from 'lodash/map';

import SwiperCore, { Pagination, A11y, EffectFade, Mousewheel, Autoplay, Scrollbar } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import Slides from '../slides';

SwiperCore.use([Pagination, A11y, EffectFade, Mousewheel, Autoplay, Scrollbar]);

export const Slider = props => {
    const itemCountToClassNames = {
        'item2': 'slide-item-two-cols',
        'item4': 'slide-item-four-cols' // TODO: not added css styles for 4 item version
    };

    const extractSlideIds = slideIndex => {
        return Array.isArray(props.slides[slideIndex]) ? props.slides[slideIndex].map(slide => slide.id) : [props.slides[slideIndex].id];
    };

    const [currentSlideIDs, setCurrentSlideIDs] = useState(extractSlideIds(0));

    const [swiper, setSwiper] = useState(null);

    const handleSlideChange = () => {
        setCurrentSlideIDs(extractSlideIds(swiper.activeIndex));
        if(props.sliderChangeHandler) {
            props.sliderChangeHandler(swiper.activeIndex);
        }
    };

    useEffect(() => {
        const { disableOnSlideChange = false } = props;

        if(swiper && !disableOnSlideChange) {
            swiper.on('slideChange', handleSlideChange);
        }
    }, [swiper]);

    const renderSlides = (slides, internal = 'internal') => {
        return map(slides, (slide, k) => {
            if (isArray(slide)) {
                const slideCountClassName = itemCountToClassNames[`item${slide.length}`];

                return (
                    <SwiperSlide key={`slide-${k}-internalwrap`} className={classnames('slide-item', slideCountClassName)}>
                        {renderSlides(slide, 'internal')}
                    </SwiperSlide>
                );
            }

            const SlideComponent = Slides[slide.config.type];
            return (
                <SwiperSlide key={`slide-${k}-${internal}`} className="slide-item">
                    <SlideComponent
                        {...slide}
                        onDialogOpen={props.onDialogOpen}
                        currentSlideIDs={currentSlideIDs}
                        index={k}
                    />
                </SwiperSlide>
            );
        });
    };

    const { config: { sliderOptions }, slides } = props;

    return (
        <Swiper
            {...sliderOptions}
            onSwiper={(swiper) => setSwiper(swiper)}
        >
            {renderSlides(slides)}
        </Swiper>
    );
};
