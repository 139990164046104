// @flow
import {
    PAGE_TYPE_INTERNAL,
    PAGE_TYPE_EXTERNAL,
    BACKGROUND_TYPE_IMAGE,
    BACKGROUND_TYPE_VIDEO,
    BACKGROUND_TYPE_SOLID_COLOR
} from '../config/consts';
import bg1 from '../images/home/about_s.jpg';
import bg2 from '../images/home/clients_s.jpg';
import bg3 from '../images/home/expertise_s.jpg';
import bg4 from '../images/home/contacts_s.jpg';



export const slides = [
    {
        id: 1,
        config: {
            type: 'InfoSlide'
        },
        "title": "pages.news.frontPageTitle",
        "description": "pages.news.frontPageDescription",
        "type": PAGE_TYPE_EXTERNAL,
        "background": {
            "small": {
                "type": BACKGROUND_TYPE_IMAGE,
                "url": bg1
            },
            "medium": {
                "type": BACKGROUND_TYPE_IMAGE,
                "url": bg1
            },
            "large": {
                "type": BACKGROUND_TYPE_IMAGE,
                "url": bg1
            },
            "extraLarge": {
                "type": BACKGROUND_TYPE_IMAGE,
                "url": bg1
            }
        }
    },
    [
        {
            id: 2,
            config: {
                type: 'InfoSlide',
                className: 'info-slide-bgk-scale'
            },
            "title": "pages.news.newsItem1.title",
            "subtitle": "pages.news.newsItem1.location",
            "type": PAGE_TYPE_INTERNAL,
            "button": {
                "textKey": "pages.news.detailsButtonLabel",
                "url": "/news/news1"
            },
            "background": {
                "small": {
                    "type": BACKGROUND_TYPE_IMAGE,
                    "url": bg2
                },
                "medium": {
                    "type": BACKGROUND_TYPE_IMAGE,
                    "url": bg2
                },
                "large": {
                    "type": BACKGROUND_TYPE_IMAGE,
                    "url": bg2
                },
                "extraLarge": {
                    "type": BACKGROUND_TYPE_IMAGE,
                    "url": bg2
                }
            },
            dialogSlides: {
                sliderOptions: {
                    slidesPerView: 1,
                    mousewheel: true,
                    direction: 'vertical',
                    speed: 450,
                    effect: 'slide',
                    scrollbar: {
                        hide: false,
                        draggable: true,
                    }
                },
                slides: [
                    [
                        {
                            id: 1,
                            config: {
                                type: 'InfoSlide'
                            },
                            "title": "pages.news.newsItem1.title",
                            "subtitle": "pages.news.newsItem1.location",
                            "background": {
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_SOLID_COLOR,
                                    "url": "#FFFFFF"
                                }
                            }
                        },
                        {
                            id: 2,
                            config: {
                                type: 'InfoSlide'
                            },
                            "background": {
                                "small": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": bg1
                                },
                                "medium": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": bg1
                                },
                                "large": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": bg1
                                },
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": bg1
                                }
                            }
                        }
                    ],
                    [
                        {
                            id: 3,
                            config: {
                                type: 'InfoSlide'
                            },
                            "title": "pages.news.newsItem1.slideTitle1",
                            "description": 'pages.news.newsItem1.slideTitleDescription1',
                            "background": {
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_SOLID_COLOR,
                                    "url": "#FFFFFF"
                                }
                            }
                        },
                        {
                            id: 4,
                            config: {
                                type: 'InfoSlide'
                            },
                            "background": {
                                "small": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": bg2
                                },
                                "medium": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": bg2
                                },
                                "large": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": bg2
                                },
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": bg2
                                }
                            }
                        }
                    ],
                    [
                        {
                            id: 5,
                            config: {
                                type: 'InfoSlide'
                            },
                            "title": "pages.news.newsItem1.slideTitle2",
                            "description": 'pages.news.newsItem1.slideTitleDescription2',
                            "background": {
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_SOLID_COLOR,
                                    "url": "#FFFFFF"
                                }
                            }
                        },
                        {
                            id: 6,
                            config: {
                                type: 'InfoSlide'
                            },
                            "background": {
                                "small": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": bg3
                                },
                                "medium": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": bg3
                                },
                                "large": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": bg3
                                },
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": bg3
                                }
                            }
                        }
                    ]
                ]
            }
        },
        {
            id: 3,
            config: {
                type: 'InfoSlide'
            },
            "title": "pages.news.newsItem2.title",
            "subtitle": "pages.news.newsItem2.location",
            "type": PAGE_TYPE_INTERNAL,
            "button": {
                "textKey": "pages.careers.detailsButtonLabel",
                "url": "/careers/job2"
            },
            "background": {
                "small": {
                    "type": BACKGROUND_TYPE_IMAGE,
                    "url": bg1
                },
                "medium": {
                    "type": BACKGROUND_TYPE_IMAGE,
                    "url": bg1
                },
                "large": {
                    "type": BACKGROUND_TYPE_IMAGE,
                    "url": bg3
                },
                "extraLarge": {
                    "type": BACKGROUND_TYPE_IMAGE,
                    "url": bg3
                }
            },
            dialogSlides: {
                sliderOptions: {
                    slidesPerView: 1,
                    mousewheel: true,
                    direction: 'vertical',
                    speed: 450,
                    effect: 'slide',
                    scrollbar: {
                        hide: false,
                        draggable: true,
                    }
                },
                slides: [
                    [
                        {
                            id: 1,
                            config: {
                                type: 'InfoSlide'
                            },
                            "title": "pages.news.newsItem2.title",
                            "subtitle": "pages.news.newsItem2.location",
                            "background": {
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_SOLID_COLOR,
                                    "url": "#FFFFFF"
                                }
                            }
                        },
                        {
                            id: 2,
                            config: {
                                type: 'InfoSlide'
                            },
                            "background": {
                                "small": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": bg1
                                },
                                "medium": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": bg1
                                },
                                "large": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": bg1
                                },
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": bg1
                                }
                            }
                        }
                    ],
                    [
                        {
                            id: 3,
                            config: {
                                type: 'InfoSlide'
                            },
                            "title": "pages.news.newsItem2.slideTitle1",
                            "description": 'pages.news.newsItem2.slideTitleDescription1',
                            "background": {
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_SOLID_COLOR,
                                    "url": "#FFFFFF"
                                }
                            }
                        },
                        {
                            id: 4,
                            config: {
                                type: 'InfoSlide'
                            },
                            "background": {
                                "small": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": bg2
                                },
                                "medium": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": bg2
                                },
                                "large": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": bg2
                                },
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": bg2
                                }
                            }
                        }
                    ],
                    [
                        {
                            id: 5,
                            config: {
                                type: 'InfoSlide'
                            },
                            "title": "pages.news.newsItem2.slideTitle2",
                            "description": 'pages.news.newsItem2.slideTitleDescription2',
                            "background": {
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_SOLID_COLOR,
                                    "url": "#FFFFFF"
                                }
                            }
                        },
                        {
                            id: 6,
                            config: {
                                type: 'InfoSlide'
                            },
                            "background": {
                                "small": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": bg3
                                },
                                "medium": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": bg3
                                },
                                "large": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": bg3
                                },
                                "extraLarge": {
                                    "type": BACKGROUND_TYPE_IMAGE,
                                    "url": bg3
                                }
                            }
                        }
                    ]
                ]
            }
        }
    ]
];
