// @flow

import React from 'react';
import DefaultPage from './DefaultPage';
import { slides } from '../../content/news';

type Props = {
    config: {
        sliderOptions: Object
    }
};

export const News = ({ config }: Props) => <DefaultPage slides={slides} config={config} />;
