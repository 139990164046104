// @flow

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import get from 'lodash/get';
import SwiperCore, { A11y, Mousewheel, Scrollbar } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { DefaultLayout } from '../layouts/Default';
import { getSlidesPerResponsiveLayout } from '../../libs/utils';
import Slides from '../common/slides';
import Dialog from "../common/Dialog";
import { clickFunctionalButton, toggleDialog } from '../../actions/index';
import { getDialogIdFromUrl } from '../../utils/routerUtility';


import {
    PAGE_TYPE_INTERNAL,
    PAGE_TYPE_EXTERNAL
} from '../../config/consts';

import './GridTypePage.css';
import MouseAnimation from '../MouseAnimation';

SwiperCore.use([A11y, Mousewheel, Scrollbar]);

const filterLinks = [
    { key: '', label: 'All', className: 'drx-filter-all' },
    { key: 'mobile', label: 'Mobile', className: 'drx-filter-mobile' },
    { key: 'web', label: 'Web', className: 'drx-filter-web' }
];

const ITEMS_PER_SLIDE = getSlidesPerResponsiveLayout();

type Props = {
    config: {
        sliderOptions: Object
    },
    slides: Array<Object>,
    changeCanvasButtonColorsAction: Function
};

type State = {
    selectedIndex: Number,
    currentSlideIndex?: number,
    filter: string,
    showMouseAnimation: boolean
};

class GridTypePageContainer extends Component<void, Props, State> {
    state: State;
    shouldReloadSlider: boolean = false;
    swiperRef: null;
    handleSlideChange: Function;

    handleClick: Function;
	typeToHandler: Object = {
        [PAGE_TYPE_INTERNAL]: (e, idx) => {
            e.preventDefault();
            // TODO: change the url but do not route and also test
            // if video start from start when state has been changed
            this.setState({ selectedIndex: idx });
            this.props.clickFunctionalButton(null, 'internal');
            this.props.toggleDialog(idx);
        },
        [PAGE_TYPE_EXTERNAL]: () => {}
    };

    constructor(props: Props) {
        super(props);

        this.handleClick = this.handleClick.bind(this);
        this.handleSlideChange = this.handleSlideChange.bind(this);

        if (this.props.changeCanvasButtonColorsAction && get(this.props.config, 'buttonColor')) {
            props.changeCanvasButtonColorsAction(this.props.config.buttonColor);
        }

        this.state = {
            currentSlideIndex: 0,
            filter: filterLinks[0].key,
            selectedIndex: getDialogIdFromUrl(),
            showMouseAnimation: true
        }
    }

    componentWillReceiveProps(nextProps: Props) {
        if (this.state.selectedIndex !== -1 && !nextProps.isDialogOpened) {
            this.setState({ selectedIndex: -1 });
        }
    }

    componentWillUpdate(nextProps, nextState) {
        if (this.state.filter !== nextState.filter) {
            this.shouldReloadSlider = true;
        }
    }

    componentDidUpdate(prevProps, prevState) {
        const { selectedIndex } = this.state;
        const buttonColorConfig = selectedIndex === -1
            ? get(this.props, 'config.buttonColor')
            : get(this.props.slides[selectedIndex - 1], 'config.buttonColor');
        if (buttonColorConfig) {
            this.props.changeCanvasButtonColorsAction(buttonColorConfig);
        }
    }

    componentWillUnmount() {
        this.props.changeCanvasButtonColorsAction({
            linesNormalColor: "#000000", linesOverColor: "#02CBC9", xNormalColor: "#FFFFFF", xOverColor: "#02CBC9"
        });

    }

    handleClick(type, id, e) {
		this.typeToHandler[type](e, id);
    }

    handleFilterClick = (filter: string) => (e: Event) => {
        e.preventDefault();
        this.setState({
            filter
        });
    };

    handleSlideChange() {
        this.setState({
            showMouseAnimation: false
        })
    }

    render () {
        const { config, config: { sliderOptions }, slides } = this.props;
        const { filter } = this.state;
        const shouldReloadSlider = this.shouldReloadSlider;
        this.shouldReloadSlider = false;
        const filteredSlides = filter ? slides.filter(s => s.category === filter) : slides;
        const slidesWithItems = filteredSlides.reduce((res, item, idx) => {
            const resIdx = Math.floor(idx/ITEMS_PER_SLIDE);
            const currentItem = res[resIdx] || [];
            res[resIdx] = [...currentItem, item];

            return res;
        }, []);

        return (
            <DefaultLayout>
                <div className="drx-grid-page">
                    <section className="drx-grid-slider-wrapper">
                        <Swiper
                            {...sliderOptions}
                            onSlideChange={this.handleSlideChange}
                        >
                            {
                                slidesWithItems.map((slide, k) => {
                                    const SlideComponent = Slides['WorkSlide'];

                                    return (
                                        <SwiperSlide key={k} className="slide-item-work">
                                            <SlideComponent slides={slide} onClick={this.handleClick} />
                                        </SwiperSlide>
                                    );
                                })
                            }
                        </Swiper>

                        {filteredSlides.map((slide, k) => {
                            const { id, dialogSlides, logo } = slide;
                            return (
                                dialogSlides ? <Dialog key={k} {...dialogSlides} logo={logo} isOpened={this.state.selectedIndex === id} /> : null
                            );
                        })}
                    </section>
                </div>
                {
                    config.mouseAnimationColor &&
                    <MouseAnimation
                        showMouseAnimation={this.state.showMouseAnimation}
                        color={config.mouseAnimationColor}
                    />
                }
            </DefaultLayout>
        );
    }
}

function mapStateToProps({ dialogState: { isOpened } }) {
    return {
        isDialogOpened: isOpened
    };
}

const mapDispatchToProps = dispatch => bindActionCreators({ clickFunctionalButton, toggleDialog }, dispatch);

export const GridTypePage = connect(mapStateToProps, mapDispatchToProps)(GridTypePageContainer);
