import { MAIN_BUTTON_CLICKED } from "../actions/index";
import { MAIN_MENU_ITEM_CLICKED } from "../actions/index";
import { MAIN_BUTTON_MODE_NORMAL, MAIN_BUTTON_MODE_X_MENU } from '../config/consts';

export default function(state = {open: false}, action) {
    switch(action.type) {
        case MAIN_BUTTON_CLICKED:
            if (action.payload.currentButtonMode === MAIN_BUTTON_MODE_NORMAL)
            {
                return { open: true };
            }
            else if (action.payload.currentButtonMode === MAIN_BUTTON_MODE_X_MENU)
            {
                return { open: false };
            }
            break;
        case MAIN_MENU_ITEM_CLICKED:
            return {open: false};
        default:
            break;
    }
    return state;
}