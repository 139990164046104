import { history } from '../Router';

export const MAIN_BUTTON_CLICKED = 'mainButtonClicked';
export const MAIN_MENU_ITEM_CLICKED = 'mainMenuItemClicked';
export const FUNCTIONAL_BUTTON_CLICKED = 'functionalButtonClicked';
export const LOGO_CLICKED = "logoClicked";
export const ABOUT_SUBPAGE_CLICKED = "aboutSubpageClicked";
export const VIDEO_COMPLETE = "videoComplete";
export const TOGGLE_DIALOG = "toggleDialog";
export const CHANGE_CANVAS_BUTTONS_COLORS = 'changeCanvasButtonsColors';

export function clickMainButton(currentButtonMode) {
    return {
        type: MAIN_BUTTON_CLICKED,
        payload: {currentButtonMode}
    }
}

export function clickMainMenuItem() {
    return {
        type: MAIN_MENU_ITEM_CLICKED
    }
}

export function clickFunctionalButton(path, pathType) {
    return {
        type: FUNCTIONAL_BUTTON_CLICKED,
        payload: {path, pathType}
    }
}

export function clickLogo() {
    return {
        type: LOGO_CLICKED,
        payload: {}
    }
}

export function completeVideo(pageIndex)
{
    return {
        type: VIDEO_COMPLETE,
        payload: { pageIndex }
    }
}

export function toggleDialog(dialogId)
{
    if (dialogId && dialogId !== -1) {
        history.replace(`${history.location.pathname}?dialog=${dialogId}`)
    } else {
        history.replace(history.location.pathname);
    }

    return {
        type: TOGGLE_DIALOG
    }
}
