import { NO_SWIPING_CLASS_NAME } from "../config/consts";

export const pageConfig = {
    "home": {
        "pathName": "/:locale",
        sliderOptions: {
            pagination: {
                type: 'bullets',
                clickable: true,
                bulletClass: "droxic-bullet-class",
                bulletActiveClass: "droxic-active-bullet-class"
            },
            slidesPerView: 1,
            mousewheel: true,
            direction: 'horizontal',
            speed: 500,
            effect: 'slide',
            autoplay: {
                delay: 46220
            },
            noSwipingClass: NO_SWIPING_CLASS_NAME,
            preventInteractionOnTransition: true
        }
    },
    "about": {
        "pathName": "/:locale/about",
        "initialSection": {
            "name": "initial",
            "logo": "string",
            "title": "pages.about.description",
            "path": "/about",
            "background": "image"
        },
        "mouseAnimationColor": '#000000',
        sliderOptions: {
            slidesPerView: 1,
            mousewheel: true,
            direction: 'vertical',
            speed: 450,
            effect: 'slide',
            scrollbar: {
                hide: false,
                draggable: true,
            },
            noSwipingClass: NO_SWIPING_CLASS_NAME,
            preventInteractionOnTransition: true
        }
    },
    "work": {
        "pathName": "/:locale/work",
        "buttonColor": {
            linesNormalColor: '#02cbc9',
            linesOverColor: '#02cbc9',
            xNormalColor: '#02cbc9',
            xOverColor: '#02cbc9'
        },
        "mouseAnimationColor": '#02cbc9',
        sliderOptions: {
            slidesPerView: 1,
            mousewheel: true,
            direction: 'vertical',
            speed: 450,
            effect: 'slide',
            scrollbar: {
                hide: false,
                draggable: true,
            }
        },
    },
    "contacts": {
        "pathName": "/:locale/contacts"
    },
    "careers": {
        "pathName": "/:locale/careers",
        "mouseAnimationColor": '#000000',
        sliderOptions: {
            slidesPerView: 1,
            mousewheel: true,
            direction: 'vertical',
            speed: 450,
            effect: 'slide',
            scrollbar: {
                hide: false,
                draggable: true,
            },
            noSwipingClass: NO_SWIPING_CLASS_NAME
        }
    },
    "news": {
        "pathName": "/:locale/news",
        "mouseAnimationColor": '#000000',
        sliderOptions: {
            slidesPerView: 1,
            mousewheel: true,
            direction: 'vertical',
            speed: 450,
            effect: 'slide',
            scrollbar: {
                hide: false,
                draggable: true,
            }
        }
    },
    "privacy": {
        "pathName": "/:locale/privacy-policy"
    },
};
