import React, { useRef, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FormattedMessage } from 'react-intl';
import { checkScreenSizeIfMobile } from '../../utils/utils';
import { clickMainButton, toggleDialog } from '../../actions/index';
import { MAIN_BUTTON_MODE_NORMAL, MAIN_BUTTON_MODE_X_MENU, MAIN_BUTTON_MODE_X_SUBSECTION } from '../../config/consts';
import { linesOver, xOver, linesOut, xOut, linesToX, xToLines } from './MainButtonTransitions';
import styles from './MainButton.module.css';

const MainButton = props => {
    const [title, setTitle] = useState(<FormattedMessage id={'menu.openMenuTooltip'} />);

    const mainBtn = useRef(null);
    const buttonSVG = useRef(null);
    const line3base = useRef(null);
    const line1base = useRef(null);
    const line3 = useRef(null);
    const line2 = useRef(null);
    const line1 = useRef(null);
    const diag2 = useRef(null);
    const diag1 = useRef(null);

    const { linesNormalColor, linesOverColor, xNormalColor, xOverColor, mode } = props;

    const clickHandler = () => {
        props.clickMainButton(props.mode);

        if (props.isDialogOpened) {
            props.toggleDialog();
        }
    };

    const mouseEnterHandler = () => {
        if (!checkScreenSizeIfMobile()) {
            if (mode === MAIN_BUTTON_MODE_NORMAL) {
                linesOver(buttonSVG, linesOverColor);
            } else {
                xOver(buttonSVG, xOverColor);
            }
        }
    };

    const mouseLeaveEvent = () => {
        if (!checkScreenSizeIfMobile()) {
            if (mode === MAIN_BUTTON_MODE_NORMAL) {
                linesOut(buttonSVG, linesNormalColor);
            } else {
                xOut(buttonSVG, xNormalColor);
            }
        }
    };

    useEffect(() => {
        if (
            mode === MAIN_BUTTON_MODE_X_MENU || mode === MAIN_BUTTON_MODE_X_SUBSECTION
        ) {
            linesToX(line1, line2, line3, diag1, diag2);
            setTitle(<FormattedMessage id={'menu.closeMenuTooltip'} />)
        } else if (mode === MAIN_BUTTON_MODE_NORMAL) {
            xToLines(line1base, line3base, line1, line2, line3);
            setTitle(<FormattedMessage id={'menu.openMenuTooltip'} />);
        }
    }, [mode]);

    return (
        <button
            type="button"
            className={styles.mainButton}
            ref={mainBtn}
            onClick={clickHandler}
            onMouseEnter={mouseEnterHandler}
            onMouseLeave={mouseLeaveEvent}
        >
            <svg
                className={styles.mainButtonSvg}
                id='buttonSVG'
                ref={buttonSVG}
                data-name='Layer 1'
                xmlns='http://www.w3.org/2000/svg'
                width='30'
                height='24.04'
                viewBox='0 0 30 24.04'
            >
                <title>{title}</title>
                <path
                    id='line3base'
                    className={styles.mainButtonSvgHiddenShape}
                    ref={line3base}
                    d='M30,18v4H0V18Z'
                />
                <path
                    id='line1base'
                    className={styles.mainButtonSvgHiddenShape}
                    ref={line1base}
                    d='M30,2V6H0V2Z'
                />
                <path
                    id='line3'
                    ref={line3}
                    d='M30,18v4H0V18Z'
                    fill={linesNormalColor}
                />
                <path
                    id='line2'
                    ref={line2}
                    d='M30,10v4H0V10Z'
                    fill={linesNormalColor}
                />
                <path
                    id='line1'
                    ref={line1}
                    d='M30,2V6H0V2Z'
                    fill={linesNormalColor}
                />
                <path
                    id='diag2'
                    className={styles.mainButtonSvgHiddenShape}
                    ref={diag2}
                    d='M24.21,0,27,2.83,5.83,24,3,21.21Z'
                    fill='#fff'
                />
                <path
                    id='diag1'
                    className={styles.mainButtonSvgHiddenShape}
                    ref={diag1}
                    d='M27,21.21,24.21,24,3,2.83,5.83,0Z'
                    fill='#fff'
                />
            </svg>
        </button>
    );
};

const mapDispatchToProps = dispatch => bindActionCreators(
    { clickMainButton, toggleDialog },
    dispatch
);

const mapStateToProps = state => ({
    isDialogOpened: state.dialogState.isOpened,
    mode: state.mainButtonState.mode,
    linesNormalColor: state.mainButtonState.linesNormalColor,
    linesOverColor: state.mainButtonState.linesOverColor,
    xNormalColor: state.mainButtonState.xNormalColor,
    xOverColor: state.mainButtonState.xOverColor
});

export default connect(mapStateToProps, mapDispatchToProps)(MainButton);
