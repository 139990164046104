// @flow

import React, { PureComponent } from 'react';
import classnames from 'classnames';
import get from 'lodash/get';
import { Source, detectSource } from '../source/Source';
// import { SlideContent } from './SlideContent';
import SlideContent from '../../SlideContent';

import './InfoSlide.css';

type Props = {
    title?: string,
    subtitle?: string,
    description?: string,
    longDescription?: string,
    companyName?: string,
    background?: Object,
    image?: Object,
    icons?: Array<*>,
    onClick?: Function,
    button?: {
        url: string,
        textKey: string
    },
    newRoute: string
};

export class InfoSlide extends PureComponent<*, Props, *> {
    render() {
        const { background, ...restProps } = this.props;
        return (
            <section className={classnames('info-slide', get(restProps, 'config.className'))}>
                { background && <Source src={detectSource(background)} className="info-slide-background" isBackground={true} />}
                <SlideContent {...restProps} onDialogOpen={this.props.onDialogOpen} />
            </section>
        );
    }
}
