import { useEffect, useRef, useState } from 'react';
import debounce from 'lodash/debounce';
import { checkScreenSizeIfMobile } from "../utils/utils";

export const usePrevious = value => {
    const ref = useRef(null);
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

export const useWindowSize = debounceDelay => {
    const [isMobile, setIsMobile] = useState(checkScreenSizeIfMobile());
    useEffect(() => {
        function handleResize() {
            console.info('resize handler');
            setIsMobile(checkScreenSizeIfMobile());
        }

        const debounced = debounce(handleResize, debounceDelay);
        window.addEventListener(`resize`, debounced);

        return () => {
            window.removeEventListener(`resize`, debounced);
        }
    }, [debounceDelay]);

    return isMobile;
}
