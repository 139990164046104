import React, { useRef, useEffect, useState } from 'react';
import {connect} from "react-redux";
import shortid from "shortid";
import classnames from 'classnames';
import { withRouter } from 'react-router';
import { gsap, Expo } from "gsap";
import {FormattedMessage} from "react-intl";

import { getDialogIdFromUrl } from '../../utils/routerUtility';
// import {detectSource, Source} from "../common/source/Source";
import Dialog from "../common/Dialog";
import styles from './SlideContent.module.css';
import ButtonLink from "../common/button/ButtonLink";
import {PAGE_TYPE_EXTERNAL, PAGE_TYPE_INTERNAL} from "../../config/consts";
import Button from "../common/button/Button";
import { usePrevious } from '../../hooks/hooks';

const SlideContent = props => {
    const {
        title,
        subtitle,
        description,
        longDescription,
        companyName,
        button,
        icons,
        linkedIn,
        type: buttonType,
        dialogSlides,
        list,
        careersGDPR,
        config
    } = props;

    const iconsContainerElement = useRef(null);
    const longDescriptionElement = useRef(null);
    const titleElement = useRef(null);
    const subtitleElement = useRef(null);
    const companyNameElement = useRef(null);
    const descriptionElement = useRef(null);
    const listElement = useRef(null);
    const careersGdprElement = useRef(null);
    const buttonElement = useRef(null);

    const getPresentedElements = () => {
        return [
            iconsContainerElement,
            longDescriptionElement,
            titleElement,
            subtitleElement,
            companyNameElement,
            descriptionElement,
            listElement,
            careersGdprElement,
            buttonElement
        ].filter(element => element.current);
    };

    const [selectedIndex, setSelectedIndex] = useState(getDialogIdFromUrl(props.id));

    const prevSlideIDs = usePrevious(props.currentSlideIDs) || [];

    useEffect(() => {
        if (props.currentSlideIDs.indexOf(props.id) !== -1) {
            showElements();
        }
        if (prevSlideIDs.indexOf(props.id) !== -1) {
            hideElements();
        }
    }, [props.currentSlideIDs]);

    useEffect(() => {
        if (!props.isDialogOpened) {
            setSelectedIndex(-1);
            if (props.onDialogOpen) {
                props.onDialogOpen(-1);
            }
        }
    }, [props.isDialogOpened])

    const showElements = (baseDelay = 0) => {
        const presentedElements = getPresentedElements();

        presentedElements.forEach((element, index) => {
            gsap.fromTo(element.current, .75,
                {
                    x: 25,
                    y: 0,
                    opacity: 0,
                },
                {
                    x: 0,
                    Y: 0,
                    opacity: 1,
                    delay: baseDelay + ((index * .15) + .5),
                    ease: Expo.easeOut
                }
            );
        });
    }

    const hideElements = () => {
        const presentedElements = getPresentedElements();

        presentedElements.forEach(element => {
            gsap.to(element.current, .45,
                {
                    x: -25,
                    y: 0,
                    opacity: 0,
                    ease: Expo.easeOut
                }
            );
        });
    };

    const buttonClickHandler = () => {
        setSelectedIndex(1);
        props.onDialogOpen(props.id);
    };

    const renderIcons = () => {
        if (icons) {
            return (
                <div ref={iconsContainerElement} className={styles.iconContainer}>
                    {
                        icons.map((icon, index) => (
                            <img
                                src={icon}
                                key={shortid.generate()}
                                alt={`technology icon ${index}`}
                                className={styles.icon}
                            />
                        ))
                    }
                </div>
            )
        }

        return null;
    }

    const renderLongDescription = () => {
        if (longDescription) {
            return (
                <p ref={longDescriptionElement} className={styles.longDescription}>
                    <FormattedMessage id={longDescription} style={{ opacity: 0 }} />
                </p>
            );
        }

        return null;
    }

    const renderTitle = () => {
        if (title) {
            return (
                <h1 ref={titleElement}
                    className={classnames({ [styles.smallerTitleSize]: config.renderSmallerFontSize })}
                    style={{ opacity: 0 }}
                >
                    <FormattedMessage id={title} />
                </h1>
            );
        }

        return null;
    }

    const renderSubtitle = () => {
        if (subtitle) {
            return (
                <h2
                    ref={subtitleElement}
                    className={classnames({ [styles.smallerSubtitleSize]: config.renderSmallerFontSize })}
                    style={{ opacity: 0 }}
                >
                    <FormattedMessage id={subtitle} />
                </h2>
            )
        }

        return null;
    }

    const renderCompanyName = () => {
        if (companyName) {
            return (
                <h3 ref={companyNameElement} style={{ opacity: 0 }}>
                    <FormattedMessage id={companyName} />
                </h3>
            )
        }

        return null;
    }

    const renderDescription = () => {
        if (description) {
            return (
                <p ref={descriptionElement} className={styles.description} style={{ opacity: 0, color: config.textColor }}>
                    <FormattedMessage id={description} />
                </p>
            )
        }

        return null;
    }

    const renderList = () => {
        if (list) {
            return (
                <ul ref={listElement} style={{ opacity: 0 }}>
                    {
                        list.map(
                            element => {
                                return(
                                    <li key={shortid.generate()}>
                                        <FormattedMessage id={element} />
                                    </li>
                                )
                            }
                        )
                    }
                </ul>
            );
        }

        return null;
    }

    const renderCareersGdpr = () => {
        if (careersGDPR) {
            return (
                <p ref={careersGdprElement} style={{ opacity: 0 }}>
                    <FormattedMessage
                      id={careersGDPR}
                      values={{
                          a: chunks => (
                            <a
                              target="_blank"
                              href="/en/privacy-policy"
                              title="More info"
                            >
                                {chunks}
                            </a>
                          ),
                          cta: chunks => <strong class="important">{chunks}</strong>,
                      }}
                    />
                </p>
            );
        }

        return null;
    }

    const renderButton = () => {
        if (button && !buttonType) {
            return (
                <div ref={buttonElement} className={styles.buttonContainer} style={{ opacity: 0 }}>
                    <ButtonLink url={button.url} isExternal>
                        <FormattedMessage id={button.textKey} />
                    </ButtonLink>
                </div>
            );
        }

        if (button && buttonType === PAGE_TYPE_EXTERNAL) {
            return (
                <div ref={buttonElement} className={styles.buttonContainer} style={{ opacity: 0 }}>
                    <ButtonLink url={`/${window.siteLanguage}${button.url}`}>
                        <FormattedMessage id={button.textKey} />
                    </ButtonLink>
                </div>
            );
        }

        if (button && buttonType === PAGE_TYPE_INTERNAL) {
            return (
                <div ref={buttonElement} className={styles.buttonContainer} style={{ opacity: 0 }}>
                    <Button {...button} onClick={buttonClickHandler}>
                        <FormattedMessage id={button.textKey} />
                    </Button>
                </div>
            );
        }

        return null;
    }

    const renderLinkedIn = () => {
        if (linkedIn) {
            return (
                <a href={linkedIn} className={styles.linkedIn}> </a>
            );
        }

        return null;
    }

    const mainContainerClassName = classnames({
        [styles.mainContainer]: true,
        [styles.mainContainerWithPagination]: config.withPagination,
        [styles.mainContainerAlignLeft]: config.horizontalAlignment === 'left',
        [styles.mainContainerAlignTop]: config.verticalAlignment === 'top',
    });

    const containerClassName = classnames({
        [styles.container]: true,
        [styles.containerAlignLeft]: config.horizontalAlignment === 'left',
        [styles.containerWithLinkedIn]: config.withLinkedIn,
        [styles.containerWithMouseAnimation]: config.withMouseAnimation
    });

    return (
        <>
            <div
                className={mainContainerClassName}
            >
                <div
                    className={containerClassName}
                >
                    {renderIcons()}
                    {renderLongDescription()}
                    {renderTitle()}
                    {renderSubtitle()}
                    {renderCompanyName()}
                    {renderDescription()}
                    {renderList()}
                    {renderCareersGdpr()}
                    {renderButton()}
                </div>
                {renderLinkedIn()}
            </div>
            {dialogSlides && <Dialog {...dialogSlides} isOpened={selectedIndex !== -1} />}
        </>
    );
};

function mapStateToProps({ dialogState: { isOpened }, newRoute }) {
    return {
        isDialogOpened: isOpened,
        newRoute: newRoute.path
    };
}

export default connect(mapStateToProps)(withRouter(SlideContent));
