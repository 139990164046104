import { FUNCTIONAL_BUTTON_CLICKED } from "../actions/index";
import { MAIN_BUTTON_CLICKED } from "../actions/index";
import { MAIN_BUTTON_MODE_X_SUBSECTION } from '../config/consts';
import { ABOUT_SUBPAGE_CLICKED } from "../actions/index";

export default function(state = {activePage: ""}, action) {
    switch(action.type) {
        case FUNCTIONAL_BUTTON_CLICKED:
            if (action.payload.pathType === "internal")
            {
                return { activePage: action.payload.path };
            }
            break;
        case MAIN_BUTTON_CLICKED:
            if (action.payload.currentButtonMode === MAIN_BUTTON_MODE_X_SUBSECTION)
            {
                return { activePage: "" };
            }
            break;
        case ABOUT_SUBPAGE_CLICKED:
            if (action.payload.pathType === "internal")
            {
                return { activePage: action.payload.path};
            }
            break;
        default:
            break;
    }
    return state;
}