// @flow

import React, { Component } from 'react';
import { Source, detectSource } from '../source/Source';
import './ClientSlide.css';

// type Props = {
//     title?: string,
//     subtitle?: string,
//     description?: string,
//     background?: Object,
//     image?: Object,
//     icons?: Array<*>,
//     onClick?: Function,
//     button?: {
//         url: string,
//         textKey: string
//     }
// };

const handleMouseOver = () => {
    //TODO: Create some cool hover animation
    // gsap.to(this.logo, 0.5, {css:{scale:.5, opacity:.6, rotation: 180}, ease:Expo.easeInOut})

}
const handleMouseOut = () => {
    //TODO: Create some cool hover animation
}

export class ClientSlide extends Component {
    shouldComponentUpdate() {
        return false;
    }

    render() {
        const { background, clientLogo, clientName } = this.props;

        return (
            <section className="client-slide">
                { background && <Source src={detectSource(background)} className="client-slide-background" isBackground={true} />}
                {clientLogo &&
                    <div className={"client-slide-details"} ref={div => { this.logo = div; }} onMouseEnter={handleMouseOver} onMouseLeave={handleMouseOut}>
                        <img src={clientLogo} alt={clientName} className={"client-slide-image"}/>
                    </div>
                }
            </section>
        );
    }

}
/*
export const ClientSlide = ({ title, subtitle, description, button, background, image, icons, clientLogo, clientLogoScale, clientName, onClick }: Props) => {
    return (
        <section className="client-slide">
            { background && <Source src={detectSource(background)} className="client-slide-background" isBackground={true} />}
            {clientLogo &&
                <div className={"client-slide-details"} ref={div => { this.logo = div; }} onMouseEnter={handleMouseOver} onMouseLeave={handleMouseOut}>
                    <img src={clientLogo} alt={clientName} className={"client-slide-image"}/>
                </div>
            }
        </section>
    );
};
*/
