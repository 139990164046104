// @flow

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';
import classnames from 'classnames';
import isArray from 'lodash/isArray';
import map from 'lodash/map';
import get from 'lodash/get';

import { DefaultLayout } from '../layouts/Default';
import Slides from '../common/slides';
import Dialog from '../common/Dialog';
import { Slider } from '../common/slider/Slider';
import { extractSlideByDevice } from '../../libs/utils.js';
import { getDialogIdFromUrl } from '../../utils/routerUtility';

import { clickFunctionalButton, toggleDialog } from '../../actions/index';

import './DefaultPage.css';
import MouseAnimation from '../MouseAnimation';

const itemCountToClassNames = {
  item2: 'slide-item-two-cols',
  item4: 'slide-item-four-cols' // TODO: not added css styles for 4 item version
};

type Props = {
  config: {
    sliderOptions: Object
  },
  slides: Array<Object>,
  changeCanvasButtonColorsAction: Function,
  animate: Boolean
};

type State = {
  activeSlideIndex: number,
  openedDialogId: number,
  showMouseAnimation: boolean
};

class DefaultPage extends Component<void, Props, State> {
  state: State;
  swiperRef: null;
  setSwiperRef: Function;
  handleDialogOpen: Function;

  constructor(props: Props) {
    super(props);

    this.setSwiperRef = this.setSwiperRef.bind(this);
    this.handleDialogOpen = this.handleDialogOpen.bind(this);
    this.handleSlideChange = this.handleSlideChange.bind(this);

    if (this.props.changeCanvasButtonColorsAction) {
      props.changeCanvasButtonColorsAction(this.props.config.buttonColor);
    }

    const openedDialogId = getDialogIdFromUrl();

    this.state = {
      activeSlideIndex: -1,
      openedDialogId,
      showMouseAnimation: true
    };
  }

  handleDialogOpen(id: number) {
    const ID = id > 0 ? id - 1 : this.state.activeSlideIndex;
    const slides = this.props.slides[ID];
    const buttonColorConfig = get(slides, 'config.buttonColor');
    if (buttonColorConfig) {
      this.props.changeCanvasButtonColorsAction(buttonColorConfig);
    }

    this.setState({
      openedDialogId: id
    });

    if (id !== -1) {
      this.props.clickFunctionalButton(null, 'internal');
      this.props.toggleDialog(id);
    }
  }

  setSwiperRef(ref: Object) {
    this.swiperRef = ref ? ref.swiper : null;
  }
  handleSlideChange(activeIndex: number) {
    if (this.props.changeCanvasButtonColorsAction) {
      const buttonColorConfig = get(
        this.props.slides[activeIndex],
        'config.buttonColor'
      );

      if (buttonColorConfig) {
        this.props.changeCanvasButtonColorsAction(buttonColorConfig);
      }
    }

    this.setState({
      activeSlideIndex: activeIndex,
      showMouseAnimation: false
    });
  }

  renderSlides = (
    slides: Array<Object>,
    internal?: number | string
  ): Array<React$Element<*>> => {
    return map(slides, (slide, k) => {
      if (isArray(slide)) {
        const slideCountClassName =
          itemCountToClassNames[`item${slide.length}`];

        return (
          <section
            key={k}
            className={classnames('slide-item', slideCountClassName)}
          >
            {this.renderSlides(slide, 'internal')}
          </section>
        );
      }

      const SlideComponent = Slides[slide.config.type];

      return (
        <section key={`slidemain-${k}-${internal}`} className='slide-item'>
          <SlideComponent {...slide} />
        </section>
      );
    });
  };

  renderDialogs = (slides: Array<Object>): Array<React$Element<*>> => {
    return map(slides, (slide, k) => {
      if (isArray(slide)) {
        return this.renderDialogs(slide);
      }

      if (slide.dialogSlides) {
        return (
          <Dialog
            key={k}
            customConfig={slide.dialogSlides.config}
            {...extractSlideByDevice(slide.dialogSlides)}
            isOpened={this.state.openedDialogId === slide.id}
          />
        );
      }

      return null;
    });
  };

  render() {
    const { config, slides, animate } = this.props;
    return (
        <DefaultLayout>
          <Slider
            config={config}
            slides={slides}
            onDialogOpen={this.handleDialogOpen}
            sliderChangeHandler={this.handleSlideChange}
          />
          {this.renderDialogs(slides)}
          {config.mouseAnimationColor && (
            <MouseAnimation
              showMouseAnimation={this.state.showMouseAnimation}
              color={config.mouseAnimationColor}
            />
          )}
        </DefaultLayout>
    );
  }
}

DefaultPage.defaultProps = {
  changeCanvasButtonColorsAction: () => {}
};

const mapDispatchToProps = dispatch =>
  bindActionCreators({ clickFunctionalButton, toggleDialog }, dispatch);

export default connect(null, mapDispatchToProps)(withRouter(DefaultPage));
