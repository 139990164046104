// @flow

import React, { PureComponent } from 'react';
import classnames from 'classnames';
import { Source, detectSource } from '../source/Source';
// import Dialog from "../Dialog";
import { Slider } from '../slider/Slider';
import './InfoSlide.css';
import get from "lodash/get";

type Props = {
    title?: string,
    subtitle?: string,
    description?: string,
    longDescription?: string,
    companyName?: string,
    background?: Object,
    dialogSlides?: Object,
    image?: Object,
    icons?: Array<*>,
    onClick?: Function,
    button?: {
        url: string,
        textKey: string
    },
    newRoute: string
};

export class SwiperSlide extends PureComponent<*, Props, *> {
    render() {
        //const { background, dialogSlides } = this.props;
        const { background, dialogSlides, ...restProps } = this.props;

        return (
            <section className={classnames('info-slide', get(restProps, 'config.className'))}>
                { background && <Source src={detectSource(background)} className="info-slide-background" isBackground={true} />}
                {/* <Dialog {...dialogSlides} isOpened={this.props.isVisible} disableOnSlideChange={true} /> */}
                <Slider config={dialogSlides} slides={dialogSlides.slides} disableOnSlideChange={true} />
            </section>
        );
    }
}
