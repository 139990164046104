import React, { useState, useEffect } from 'react';
import SwiperCore, { Pagination, A11y, EffectFade } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useWindowSize } from "../../../hooks/hooks";
import ContactPage from './ContactPage';
import { getContacts, getSwiperParams } from './utils';
import styles from './Contacts.module.css';

SwiperCore.use([Pagination, A11y, EffectFade]);

const ContactsPage = () => {
    const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
    const isMobile = useWindowSize(200);
    const contacts = getContacts();
    const swiperParams = getSwiperParams(isMobile);
    const [swiper, setSwiper] = useState(null);

    const onCityClick = event => {
        const nextSlideIndex = parseInt(event.currentTarget.id.substring(4), 10);
        swiper.slideTo(nextSlideIndex);
        setCurrentSlideIndex(nextSlideIndex);
    };

    useEffect(() => {
        if(isMobile && swiper && !swiper.destroyed) {
            swiper.on('transitionStart', () => {
                setCurrentSlideIndex(swiper.activeIndex);
            });
        }
    }, [isMobile, swiper]);

    const renderCities = pages => (
        <ul>
            {
                pages.map((contact, index) => (
                    <li
                        id={`city${index}`}
                        key={contact.menuName}
                        className={
                            (currentSlideIndex === index) ?
                                styles.selectedCity :
                                ''
                        }
                        onClick={onCityClick}
                    >
                        {contact.menuName}
                    </li>
                ))
            }
        </ul>
    );

    const renderSwiper = () => {
        if (swiper && !swiper.destroyed) {
            if(swiper.activeIndex !== currentSlideIndex) {
                swiper.slideTo(currentSlideIndex);
            }
        }
        return (
            <Swiper
                id="contactSwiper"
                className='swiper-container-centered'
                onSwiper={(swiper) => setSwiper(swiper)}
                {...swiperParams}
            >
                {
                    contacts.map(contact => (
                        <SwiperSlide key={contact.index}>
                            <ContactPage isMobile={isMobile} {...contact} currentSlideIndex={currentSlideIndex}/>
                        </SwiperSlide>
                    ))
                }
            </Swiper>
        )
    }

    return (
        <div className={styles.mainContainer}>
            {
                isMobile &&
                renderSwiper()
            }
            {
                !isMobile &&
                <div className={styles.leftContainer}>
                    {renderSwiper()}
                </div>

            }
            {
                !isMobile &&
                <div className={styles.rightContainer}>
                    {renderCities(contacts)}
                </div>
            }
        </div>
    );
};

export default ContactsPage;
