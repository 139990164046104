import React, { useRef, useEffect } from 'react';
import { gsap, Expo, Sine } from 'gsap';
import styles from './MouseAnimation.module.css';

const MouseAnimation = props => {
    const mouseAnimation = useRef(null);
    const border = useRef(null);
    const dot = useRef(null);
    const bigCircle = useRef(null);
    const topCircle = useRef(null);
    const bottomCircle = useRef(null);
    const circle = useRef(null);

    useEffect(() => {
        const tl = gsap.timeline({repeat: -1, repeatDelay: 0});
        tl.to( circle.current, .4, {morphSVG: {shape: bigCircle.current }, ease: Expo.easeOut});
        tl.to( circle.current, .4, {morphSVG: {shape: bottomCircle.current }, ease: Expo.easeOut});
        tl.to( circle.current, .5, {morphSVG: {shape: topCircle.current }, ease: Expo.easeInOut});

        if (props.showMouseAnimation) {
            gsap.fromTo(mouseAnimation.current, .75, { opacity: 0}, {opacity: 1, ease:Sine.easeOut, delay: 1});
        } else {
            gsap.set(mouseAnimation.current, {
                opacity: 0
            });
        }
        gsap.set(border.current, { borderColor: props.color});
        gsap.set(dot.current, { fill: props.color });
    }, []);

    useEffect(() => {
        if (!props.showMouseAnimation) {
            gsap.to(mouseAnimation.current, .3, { opacity: 0, ease:Sine.easeOut });
        }
    }, [props.showMouseAnimation]);

    return (
        <div className={styles.container} ref={mouseAnimation}>
            <div className={styles.mouseShape} ref={border} />
            <svg className={styles.wheel} ref={dot}  id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width="10" height="20" viewBox="0 0 10 20">
                <title>Rectangle</title>
                <path id="big_circle" ref={bigCircle} className={styles.wheelHiddenShapes} d="M5,17H5a5,5,0,0,1-5-5V7A5,5,0,0,1,5,2H5a5,5,0,0,1,5,5v5A5,5,0,0,1,5,17Z" transform="translate(0)"/>
                <path id="top_circle" ref={topCircle} className={styles.wheelHiddenShapes} d="M10,5A5,5,0,1,1,5,0,5,5,0,0,1,10,5Z" transform="translate(0)"/>
                <path id="bottom_circle" ref={bottomCircle} className={styles.wheelHiddenShapes} d="M10,15a5,5,0,1,1-5-5A5,5,0,0,1,10,15Z" transform="translate(0)"/>
                <path id="circle" ref={circle} d="M10,5A5,5,0,1,1,5,0,5,5,0,0,1,10,5Z" transform="translate(0)"/>
            </svg>
        </div>
    );
}

export default MouseAnimation;
