// @flow

import React from 'react';

type Props = {
    children: Array<*>
};

export const DefaultLayout = ({ children }: Props) => {
    return (
        <div className="drx-default drx-animated-page">
            {children}
        </div>
    )
}
