import { InfoSlide } from './InfoSlide';
import { ClientSlide } from "./ClienSlide";
import { WorkSlide } from "./WorkSlide";
import { WorkDialogSlide } from "./WorkDialogSlide";
import { SwiperSlide } from "./SwiperSlide";

export default {
    InfoSlide,
    ClientSlide,
    WorkSlide,
    WorkDialogSlide,
    SwiperSlide
};
