// @flow

import React from 'react';
import find from 'lodash/find';
import trim from 'lodash/trim';
import omit from 'lodash/omit';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { detectResponsiveLayout } from '../../../libs/utils';
import { gsap, Sine, Expo, Back, Quart, Quad } from 'gsap';
import {
    BACKGROUND_TYPE_IMAGE,
    BACKGROUND_TYPE_VIDEO,
    BACKGROUND_TYPE_SOLID_COLOR
} from '../../../config/consts';
// import {SlideContentComponent} from "../slides/SlideContent";

type Props = {
    src: Object,
    className?: string,
    isBackground?: boolean
};

const mapSourceToComponent = {
    [BACKGROUND_TYPE_SOLID_COLOR]: ({ src, isBackground, ...rest }) => <div style={{ backgroundColor: src }} {...rest} />,
    [BACKGROUND_TYPE_IMAGE]: ({ src, isBackground, ...rest }) => isBackground ? <div style={{ backgroundImage: `url(${src})`}} {...rest} /> : <img src={src} alt="" {...rest} />,
    [BACKGROUND_TYPE_VIDEO]: ({ src, isBackground, ...rest }) => (
        <video playsInline muted autoPlay loop {...rest} className="video-cover" >
            <source src={src} type="video/mp4" />
        </video>
    )
};

export const detectSource = (sources: Object) => {
    const layoutKey = detectResponsiveLayout();
    const source = sources[layoutKey];

    if (source) {
        return source;
    }

    return find(sources, i => i);
};

class SourceComponent extends React.Component
{
    componentDidMount() {
        //gsap.fromTo(this.sourceRef.childNodes[0], .5, {alpha: 0}, {alpha: 1, ease: Quart.easeIn});
    }

    componentDidUpdate(prevProps) {
        const { newRoute: prevRoute } = prevProps;
        const { newRoute } = this.props;
        if (trim(prevRoute, '/') !== trim(newRoute, '/')) {
            gsap.to(this.sourceRef.childNodes[0], .3, {alpha: 0, ease: Quart.easeIn, delay: .3});
        }
    }

    render() {
        const {src, className, isBackground = false, ...restProps} = this.props;
        const { type, url } = src;
        const Component = mapSourceToComponent[type || BACKGROUND_TYPE_SOLID_COLOR];
        return(
            <span ref={elem => this.sourceRef = elem}>
                <Component src={url} className={classnames(className)} isBackground={isBackground} {...omit(restProps, ['newRoute', 'dispatch'])} />
            </span>
        )
    }
}

function mapStateToProps({ newRoute }) {
    return {
        newRoute: newRoute.path
    };
}
export const Source = connect(mapStateToProps)(SourceComponent);
