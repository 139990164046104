// @flow

export const PAGE_TYPE_INTERNAL = "pageTypeInternal";
export const PAGE_TYPE_EXTERNAL = "pageTypeExternal";
export const BACKGROUND_TYPE_IMAGE = "backgroundTypeImage";
export const BACKGROUND_TYPE_VIDEO = "backgroundTypeVideo";
export const BACKGROUND_TYPE_SOLID_COLOR = "backgroundTypeSolidColor";

export const TABLET_WIDTH = 768;
export const TABLET_HEIGHT = 500;
export const SMALL_DESKTOP_WIDTH = 1024;
export const SMALL_DESKTOP_HEIGHT = 600;
export const BIG_DESKTOP_WIDTH = 1440;
export const BIG_DESKTOP_HEIGHT = 700;

export const MAIN_BUTTON_MODE_NORMAL = 'mainButtonModeNormal';
export const MAIN_BUTTON_MODE_X_MENU = 'mainButtonModeXMenu';
export const MAIN_BUTTON_MODE_X_SUBSECTION = 'mainButtonModeXSubsection';

export const NO_SWIPING_CLASS_NAME = 'noSwipingClass';
