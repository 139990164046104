// @flow

import React from 'react';
import nth from 'lodash/nth';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import { createLogger } from 'redux-logger';
import { IntlProvider } from 'react-intl';
import { gsap } from 'gsap';
import { MorphSVGPlugin} from "gsap/MorphSVGPlugin";
import { flattenDictionary } from './libs/utils';
import reducers from './reducers';
import localeData from './locales.js';

import RouterComponent from './Router';

const AVAILABLE_LANGS = Object.keys(localeData);
const DEFAULT_LANGUAGE = AVAILABLE_LANGS[0];

// i18n
const detectedURLLang = nth(window.location.pathname.split('/'), 1);
const urlLang = AVAILABLE_LANGS.indexOf(detectedURLLang) !== -1 ? detectedURLLang : undefined;
let language = urlLang || (navigator.languages && navigator.languages[0]) || navigator.language || navigator.userLanguage;

if (language.indexOf('_') !== -1) {
  language = language.toLowerCase().split(/[_-]+/)[0];
}

if (AVAILABLE_LANGS.indexOf(language) === -1) {
  language = DEFAULT_LANGUAGE;
}

window.siteLanguage = language;

const messages = localeData[language];
const logger = createLogger();
const createStoreWithMiddleware = applyMiddleware(logger)(createStore);

gsap.registerPlugin(MorphSVGPlugin);

const App = () => (
  <IntlProvider locale={language} messages={flattenDictionary(messages)}>
    <Provider store={createStoreWithMiddleware(reducers, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),)}>
        <RouterComponent />
    </Provider>
  </IntlProvider>
);

export default App;
