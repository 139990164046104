// @flow

import React from 'react';
import shortid from 'shortid';
import map from 'lodash/map';
import { FormattedMessage } from 'react-intl';
import { Router, Switch, Route, Redirect } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { Transition } from 'react-transition-group';
import { gsap } from 'gsap';

import { pageConfig } from './schema/pages_config';

import Logo from './components/Logo';
import MainMenu from './components/Menu';
import CookieMessage from './components/CookieMessage';

import { Home } from './components/pages/Home';
import { About } from './components/pages/About';
import { Work } from './components/pages/Work';
import Contacts from './components/pages/contacts/Contacts';
import { Careers } from './components/pages/Careers';
import { News } from './components/pages/News';
import { PrivacyPolicy } from './components/pages/PrivacyPolicy';

const mapPathToComponent = {
  home: Home,
  about: About,
  work: Work,
  contacts: Contacts,
  careers: Careers,
  news: News,
  privacy: PrivacyPolicy
};

const renderRoutes = map(pageConfig, ({ pathName, ...rest }, key) => (
  <Route key={shortid.generate()} exact path={pathName}>
    {({ match }) => {
      const Component = mapPathToComponent[key];
      const hasMatch = match !== null;

      return (
        <Transition
          unmountOnExit
          in={hasMatch}
          onEnter={node => {
            gsap.set(node, { alpha: 0 });
          }}
          addEndListener={(node, doneCallback) => {
            gsap.to(node, 0.8, {
              alpha: hasMatch ? 1 : 0,
              onComplete: doneCallback
            });
          }}
        >
          <Component config={rest} />
        </Transition>
      );
    }}
  </Route>
));

export const history = createBrowserHistory();

const RouterComponent = () => (
  <Router history={history}>
    <div className={'main-container'}>
      <Switch>
        <Redirect exact from='/' to='/en' />
      </Switch>

      <Logo />
      <MainMenu />
      <CookieMessage
        text={
          <FormattedMessage
            id='privacy.text'
            values={{
              a: chunks => (
                <a
                  href="/en/privacy-policy"
                  title="More info"
                >
                  {chunks}
                </a>
              ),
              cta: chunks => <strong class="important">{chunks}</strong>,
            }}
          />
        }
        buttonText={<FormattedMessage id='privacy.buttonText' />}
      />
      {renderRoutes}
    </div>
  </Router>
);

export default RouterComponent;
