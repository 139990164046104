import compact from "lodash/compact";
import { LOGO_CLICKED } from "../actions/index";
import { FUNCTIONAL_BUTTON_CLICKED } from '../actions/index';
import { ABOUT_SUBPAGE_CLICKED } from "../actions/index";

const splittedPathname = compact(window.location.pathname.split('/'));
const DEFAULT_PATH = (splittedPathname.length > 1 ? splittedPathname.pop() : '/') || '/';

export default function(state = {path: DEFAULT_PATH}, action) {
    switch(action.type) {
        case LOGO_CLICKED:
            return {path: '/'}
        case FUNCTIONAL_BUTTON_CLICKED:
            if(action.payload.pathType === "external")
            {
                return {path: action.payload.path};
            }
            break;
        case ABOUT_SUBPAGE_CLICKED:
            if(action.payload.pathType === "external")
            {
                return {path: action.payload.path};
            }
            break;
        default:
            break;
    }
    return state;
}