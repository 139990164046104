import {Expo, Sine, gsap} from "gsap";

export const linesOver = (buttonSVG, linesOverColor) => {
    gsap.to(buttonSVG.current, 0.2, {
        scaleX: 1.25,
        scaleY: 1.25,
        transformOrigin: '50% 50%',
        fill: linesOverColor,
        ease: Sine.easeOut
    });
};

export const xOver = (buttonSVG, xOverColor) => {
    gsap.to(buttonSVG.current, 0.2, {
        scaleX: 1.25,
        scaleY: 1.25,
        transformOrigin: '50% 50%',
        fill: xOverColor,
        ease: Sine.easeOut
    });
};

export const linesOut = (buttonSVG, linesNormalColor) => {
    gsap.to(buttonSVG.current, 0.2, {
        scaleX: 1,
        scaleY: 1,
        transformOrigin: '50% 50%',
        fill: linesNormalColor,
        ease: Sine.easeOut
    });
};

export const xOut = (buttonSVG, xNormalColor) => {
    gsap.to(buttonSVG.current, 0.2, {
        scaleX: 1,
        scaleY: 1,
        transformOrigin: '50% 50%',
        fill: xNormalColor,
        ease: Sine.easeOut
    });
};

export const linesToX = (line1, line2, line3, diag1, diag2) => {
    const duration = 0.5;
    const ease = Expo.easeOut;
    gsap.to(line1.current, duration, {
        morphSVG: { shape: diag1.current, shapeIndex: [0] },
        ease: ease
    });
    gsap.to(line3.current, duration, {
        morphSVG: { shape: diag2.current, shapeIndex: [0] },
        ease: ease
    });
    gsap.to(line2.current, duration, { opacity: '0', ease: ease });
};

export const xToLines = (line1base, line3base, line1, line2, line3) => {
    const duration = 0.5;
    const ease = Expo.easeOut;
    gsap.to(line1.current, duration, {
        morphSVG: { shape: line1base.current, shapeIndex: [0] },
        ease: ease
    });
    gsap.to(line3.current, duration, {
        morphSVG: { shape: line3base.current, shapeIndex: [0] },
        ease: ease
    });
    gsap.to(line2.current, duration, { opacity: '1', ease: ease });
};