import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { completeVideo } from '../actions/index';


import '../styles/page_background.css';

export const BACKGROUND_TYPE_IMAGE = "backgroundTypeImage";
export const BACKGROUND_TYPE_VIDEO = "backgroundTypeVideo";
class PageBackground extends Component {
    currentSlideIndex = 0;
    previousIndex = 0;


    constructor(props)
    {
        super(props);
        this.onEnded = this.onEnded.bind(this);
    }



    selectImage() {
        let windowWidth = document.documentElement.clientWidth;

        if (this.props.backgroundType == BACKGROUND_TYPE_IMAGE)
        {
            let image = this.props.backgroundImages["extraLargeImg"];

            if (windowWidth < 768) {
                image = this.props.backgroundImages["small"];
            }
            else if (windowWidth < 992) {
                image = this.props.backgroundImages["mediumImg"];
            }
            else if (windowWidth < 1200) {
                image = this.props.backgroundImages["largeImg"];
            }
            return image;
        }
        else if (this.props.backgroundType == BACKGROUND_TYPE_VIDEO)
        {
            return this.props.backgroundVideos["extraLargeVideo"];
        }

    }

    onEnded(event)
    {
        this.props.completeVideo(this.props.index);
    }

    componentDidMount()
    {
        if(this.props.backgroundType == BACKGROUND_TYPE_VIDEO)
        {
            if (this.props.index == this.props.currentSlideIndex)
            {
                this.videoPlayer.play();
            }
            else
            {
                this.videoPlayer.pause();
            }
        }
    }

    componentDidUpdate(prevProps, prevState)
    {
        if (this.props.currentSlideIndex != this.currentSlideIndex || this.props.previousIndex != this.previousIndex)
        {
            if(this.props.backgroundType == BACKGROUND_TYPE_VIDEO && this.props.index == this.props.previousIndex)
            {
                this.videoPlayer.pause();
            }


            if(this.props.backgroundType == BACKGROUND_TYPE_VIDEO && this.props.index == this.props.currentSlideIndex)
            {
                this.videoPlayer.play();
                this.videoPlayer.currentTime = 0;
            }

            this.currentSlideIndex = this.props.currentSlideIndex;
            this.previousIndex = this.props.previousIndex;
        }

        if (this.props.topSectionState.activePage != prevProps.topSectionState.activePage)
        {
            if (this.props.index == this.props.currentSlideIndex && this.props.backgroundType == BACKGROUND_TYPE_VIDEO) {
                if (this.props.topSectionState.activePage != "") {
                    this.videoPlayer.pause();
                }
                else {
                    this.videoPlayer.play();
                }
            }
        }
    }


    render() {
        let image = this.selectImage();
        /*
        Based on this.props.backgroundType we have to make this component to work with either image or video.
        Possible values: "image" or "video"
         */
        if(this.props.backgroundType == BACKGROUND_TYPE_IMAGE)
        {
            let className = (this.props.isTeamImage) ? 'page-background__team-image' : 'page-background__image';
            return(
                <div className={className} style={{backgroundImage: `url(${image})`}}></div>
            );
        }
        else
        {
            return(

                <div>
                    <video ref = {div => {this.videoPlayer = div;}} className="page-background__video" playsInline muted autoPlay onEnded={this.onEnded}>
                        <source src={image} type="video/mp4" />
                    </video>
                </div>
            );
        }
    }
}
function mapStateToProps(state)
{
    return(
        {
            topSectionState: state.topSectionState
        }
    );
}
function mapDispatchToProps(dispatch)
{
    return bindActionCreators({completeVideo: completeVideo}, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(PageBackground);